import { useNavigate } from 'react-router-dom';
import { InventoryInfo } from '../../types/interface';
import { useUserStore } from '../../store';
import { useState } from 'react';

interface InventoryProps {
  token: string;
}

export const Inventory = (props: InventoryProps) => {
  const navigate = useNavigate();
  const userInfo = useUserStore((state) => state.user);
  const { token } = props;
  const tg = window.Telegram.WebApp;

  const [isLoading, setIsLoading] = useState(true);

  // const [inventories, setInventories] = useState<any[]>([]);
  const handleItem = (item: InventoryInfo) => {
    const categories = userInfo.inventories?.filter((one: InventoryInfo) => one.good.type === item.good.type);
    if (!categories || categories.length === 0) return;

    // const isEquip = categories?.find((item: InventoryInfo) => item.isEquipped);
    tg.BackButton.show();
    navigate(`/inventory/details/${item.good.id}`);
  };
  // useEffect(() => {
  //     const fetchInventory = async () => {
  //         try {
  //             const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/inventory/`, {
  //                 headers: { "Authorization": `Bearer ${token}` }
  //             });
  //             console.log("Fetched inventory data:", data); // Debug logging
  //             setInventories(data); // Assuming data is an array of ShopItemInfo
  //             setUserInfo({...userInfo, inventories: data});
  //         } catch (error) {
  //             console.error("Failed to fetch inventory", error);
  //         }
  //     };

  //     fetchInventory();
  // }, [token]); // Add token and totalExp as dependencies
  return (
    <div>
      <div className="grid gap-3 grid-cols-4">
        {userInfo.inventories && userInfo.inventories?.length > 0 ? (
          userInfo.inventories.map((item: any, index: number) => (
            <div key={index} className="relative rounded-2xl">
              <button
                onClick={() => handleItem(item)}
                className={`${
                  item?.isEquipped === true && 'opacity-100'
                } relative z-1 rounded-2xl bg-transparent w-full h-auto pointer-events-auto`}
              >
                <img
                  src={item.good.img}
                  className="rounded-2xl w-full h-full"
                  alt="item"
                  onLoad={() => setIsLoading(false)}
                />
                {isLoading && (
                  <div className="absolute top-0 left-0 w-full h-full bg-slate-700 animate-pulse rounded-2xl" />
                )}
              </button>
              {item.amount > 1 && (
                <span className="absolute z-2 left-1 top-1 bg-gray-800/[.6] rounded-lg font-bold text-xs text-white px-2">
                  x{item.amount}
                </span>
              )}
              {item?.good.type === 'skin' || item?.good.type === 'decoration' ? (
                <>
                  {item?.isEquipped === true ? (
                    <span className="absolute z-2 left-1 top-1">
                      <img src="https://notwise.b-cdn.net/icons/equi.svg" className="w-5 min-w-5" alt="icon" />
                    </span>
                  ) : (
                    <span className="absolute z-2 left-1 top-1">
                      <img src="https://notwise.b-cdn.net/icons/unequi.svg" className="w-5 min-w-5" alt="icon" />
                    </span>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          ))
        ) : (
          <span className="text-white/[50%] flex text-sm">Empty</span>
        )}
      </div>
    </div>
  );
};
