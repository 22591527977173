import * as THREE from 'three';
import { DUMMY_ANIMATION_TIMELINE, EGG_ANIMATION_TIMELINE, OWL_ANIMATION_TIMELINE } from '../constants';

type AnimationCache = {
  actionUuid: string;
  ref: NodeJS.Timeout | number | null;
}

const animationRefs: Record<string, AnimationCache> = {};

export const handleAnimation = ({
  target,
  model,
  action,
  animationName,
  isLoop,
  frameRate = 30,
  onComplete,
}: {
  target: THREE.Object3D | null;
  model: string;
  action: any;
  animationName: string;
  isLoop: boolean;
  frameRate?: number;
  onComplete?: () => void; // Add onComplete callback
}) => {
  if (!action) {
    return;
  }

  let animationData;

  // Fetch the animation data based on the model and animation name
  switch (model) {
    case 'egg': {
      animationData = EGG_ANIMATION_TIMELINE[animationName];
      break;
    }
    case 'owl': {
      animationData = OWL_ANIMATION_TIMELINE[animationName];
      break;
    }
    case 'dummy': {
      animationData = DUMMY_ANIMATION_TIMELINE[animationName];
      break;
    }
    default: {
      console.warn(`Unknown model: ${model}`);
      return;
    }
  }

  if (!animationData) {
    console.warn(`Unknown animation: ${animationName}`);
    return;
  }

  const targetUuid = `${(target?.name || target?.uuid) ?? 'global'}`;

  if (animationRefs[targetUuid]) {
    const { ref } = animationRefs[targetUuid];

    clearTimeout(ref as NodeJS.Timeout);
    delete animationRefs[targetUuid];
  }

  action.reset();

  const actionUuid = THREE.MathUtils.generateUUID();
  const { startFrame, endFrame } = animationData;
  const duration = (endFrame - startFrame) / frameRate;

  action.time = startFrame / frameRate;
  action.play();

  animationRefs[targetUuid] = {
    actionUuid,
    ref: null
  };

  // Handle looping manually to ensure it loops only over the specified frames
  if (isLoop) {
    const loopAnimation = () => {
      if (animationRefs[targetUuid]?.actionUuid !== actionUuid) {
        // NOTE Handle race condition in which RAF would execute at the same time as the action is overriden
        return;
      }

      const elapsedTime = action.time * frameRate;
      if (elapsedTime >= endFrame) {
        action.time = startFrame / frameRate;
      }

      animationRefs[targetUuid].ref = setTimeout(loopAnimation, 1000 / frameRate);
    };
    animationRefs[targetUuid].ref = setTimeout(loopAnimation, 1000 / frameRate);
  } else {
    animationRefs[targetUuid].ref = setTimeout(() => {
      if (animationRefs[targetUuid]?.actionUuid !== actionUuid) {
        // NOTE Handle race condition in which setTimeout would execute at the same time as the action is overriden
        return;
      }

      action.paused = true;

      delete animationRefs[targetUuid];

      if (onComplete) {
        onComplete();
      }
    }, duration * 1000);
  }
};
