import client from '../../utils/tonapi';
import { useNavigateByExp } from '../../hooks';
import { useGameStore, useUserStore } from '../../store';
import { useShopStoreInitializer } from '../../hooks/useShopStoreInitializer';
import { useUserInitializer } from '../../hooks/useUserInitializer';

const hasNFT = async (addressId: string) => {
  try {
    const res = await client({
      url: `https://tonapi.io/v2/accounts/${addressId}/nfts?collection=${
        process.env.REACT_APP_TEST === 'true'
          ? process.env.REACT_APP_TEST_NFT_COLLECTION1
          : process.env.REACT_APP_NFT_COLLECTION1
      }`,
      method: 'GET',
    });
    if (res.data.nft_items.length === 0) {
      try {
        const res = await client({
          url: `https://tonapi.io/v2/accounts/${addressId}/nfts?collection=${
            process.env.REACT_APP_TEST === 'true'
              ? process.env.REACT_APP_TEST_NFT_COLLECTION1
              : process.env.REACT_APP_NFT_COLLECTION2
          }`,
          method: 'GET',
        });
        return res.data.nft_items.length > 0;
      } catch {
        return false;
      }
    } else {
      return true;
    }
  } catch {
    return false;
  }
};

export const TonConnectProvider = ({ children }: { children: React.ReactNode }) => {
  //const [tonConnectUI] = useTonConnectUI();
  //const userFriendlyAddress = useTonAddress();
  //const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();

  const setUserInfo = useUserStore((state) => state.setUser);
  const setGameStage = useGameStore((state) => {
    return state.setGameStage;
  });
  //const setWallet = useWalletStore((state) => {
  //  return state.setWallet;
  //});

  useShopStoreInitializer();
  useUserInitializer();

  return <>{children}</>;
};
