import { Box3, Object3D, Vector3 } from 'three';
import { Camera } from '@react-three/fiber';
import { Coords } from '../components/GameSceneNew/components';

type TCamera = Camera & { manual?: boolean };

type Offset = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

type Parameters = {
  model: Object3D;
  point: Coords;
  camera: TCamera;
  offset: Offset;
};

type EllipseBounds = {
  left: { x: number; y: number };
  right: { x: number; y: number };
  top: { x: number; y: number };
  bottom: { x: number; y: number };
};

const project = (point: Vector3, camera: TCamera) => {
  const vector = point.clone().project(camera);
  return {
    x: ((vector.x + 1) / 2) * window.innerWidth,
    y: ((-vector.y + 1) / 2) * window.innerHeight,
  };
};

const calculateEllipseBounds = (model: Object3D, camera: TCamera, offset: Offset) => {
  const box = new Box3().setFromObject(model, true);
  const center = box.getCenter(new Vector3());

  const top = new Vector3(center.x, box.max.y + offset.top, center.z);
  const bottom = new Vector3(center.x, box.min.y + offset.bottom, center.z);
  const left = new Vector3(box.min.x + offset.left, center.y, center.z);
  const right = new Vector3(box.max.x + offset.right, center.y, center.z);

  return {
    left: project(left, camera),
    right: project(right, camera),
    top: project(top, camera),
    bottom: project(bottom, camera),
  };
};

const isPointInEllipse = (point: Coords, ellipseBounds: EllipseBounds) => {
  const a = (ellipseBounds.right.x - ellipseBounds.left.x) / 2;
  const b = (ellipseBounds.top.y - ellipseBounds.bottom.y) / 2;
  const h = (ellipseBounds.left.x + ellipseBounds.right.x) / 2;
  const k = (ellipseBounds.top.y + ellipseBounds.bottom.y) / 2;

  const normalizedX = (point.x - h) / a;
  const normalizedY = (point.y - k) / b;

  return Math.pow(normalizedX, 2) + Math.pow(normalizedY, 2) <= 1;
};

export const detectSwipeModelIntersection = ({ model, point, camera, offset }: Parameters) => {
  const ellipseBounds = calculateEllipseBounds(model, camera, offset);

  return isPointInEllipse(point, ellipseBounds);
};
