import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { useGameStore, useUserStore } from '../../store';
import { useNavigateByExp } from '../../hooks';
import { Canvas, RootState } from '@react-three/fiber';
import { STAGES } from '../../config';
import { Scene } from './components/Scene';
import { AssetsPreloader } from './components/AssetsPreloader';
import { usePrecompileRenderer } from './hooks/useGLTF';
import { getViewByGameMode } from './views';
import { useOwlBattleService } from './hooks/useOwlBattleService';
import { Layer } from './components/composition/Layer';
import { useWebSocketContext } from '../WebSocketContext';
import { useNavigate } from 'react-router';
import { DebugScene } from './components/DebugScene';
import AchievementListener from "../WebSocketContext/AchievementListener";

export const GameSceneNew = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const { setCurrentPointerCoords, gameMode, setGameMode } = useGameStore();
  const stage = useGameStore((state) => state.gameStage);
  const activeView = getViewByGameMode(gameMode);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const webSocketContext = useWebSocketContext();

  usePrecompileRenderer(rendererRef.current);
  useOwlBattleService();

  const totalExp = useUserStore((state) => state.user.totalExp);

  useEffect(() => {
    if (totalExp >= STAGES[stage + 1]) {
      navigateByExp(totalExp);
    }
  }, [totalExp]);

  useEffect(() => {
    if (!webSocketContext.lastMessage) {
      return;
    }

    const message = JSON.parse(webSocketContext.lastMessage.data);

    if (message.type === 'FIGHT_UPDATE' && gameMode !== 'battle') {
      setGameMode('battle');
      navigate('/fight');
    }
  }, [webSocketContext.lastMessage])

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setCurrentPointerCoords({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  const onCreated = (state: RootState) => {
    rendererRef.current = state.gl;
  };

  return (
    <div className="h-dvh w-full z-0 absolute">
      <AchievementListener/>
      <AssetsPreloader viewId={activeView}>
        <Canvas
          shadows={false}
          onCreated={onCreated}
          onTouchMove={onTouchMove}
        >
          <Layer renderOrder={0}>
            <Scene />
            {/* <DebugScene /> */}
          </Layer>
          <Layer renderOrder={1} />
        </Canvas>
      </AssetsPreloader>
    </div>
  );
};
