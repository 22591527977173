import { create } from 'zustand';
import { ShopInfo } from '../types/interface';

export const useShopStore = create<ShopInfo>((set) => ({
  storeGoods: null,
  setStoreGoods: (storeGoods) =>
    set((state) => ({
      ...state,
      storeGoods,
    })),
}));
