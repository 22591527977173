import useAnimation from '../../hooks/useAnimation';
import { useNavigate } from 'react-router-dom';
import IconClose from '../../assets/icon-close.svg';
import { MainButton } from '../../components';

type Props = {
  onCloseClick: () => void;
};

export const EggContentScreen = ({ onCloseClick }: Props) => {
  const navigate = useNavigate();

  const elementRef = useAnimation({
    from: { opacity: 0, y: 20 },
    to: { duration: 1, opacity: 1, y: 0, ease: 'power3.out', stagger: 0.2 },
  });

  const hasEgg = true;

  return (
    <div className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 bg-[#080D1B]">
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 bottom-0 rounded-[50%] z-0"></div>

      <div
        className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 px-5 pt-6"
        style={{
          backdropFilter: 'blur(36px) brightness(70%)',
        }}
      >
        <div className="h-full w-full flex flex-col">
          <button
            className="w-9 h-9 mb-3 pointer-events-auto bg-center bg-cover self-end"
            onClick={onCloseClick}
            style={{ backgroundImage: `url("${IconClose}")` }}
          />

          <div className="flex flex-col justify-between h-full">
            <div ref={elementRef}>
              <div className="w-full text-center mt-16 text-white text-xl   font-semibold">That's what you found</div>

              <div className="grid grid-cols-3 gap-4 mt-8 justify-center">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <img
                    key={item}
                    className="rounded-2xl w-full max-w-[112px] h-auto"
                    src={'https://staging.masonsplay.com/assets/images/egg1.webp'}
                    alt="item"
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col space-y-2 mb-6">
              <MainButton
                isLoading={false}
                btnText={hasEgg ? 'Open one more' : 'Buy one more'}
                onClick={hasEgg ? () => {} : () => navigate('/store')}
              />

              <button
                type="button"
                onClick={() => navigate('/profile')}
                className="pointer-events-auto w-full text-white font-medium rounded-lg text-sm px-5 py-3 text-center"
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.14)',
                  backdropFilter: 'blur(11.139798164367676px)',
                }}
              >
                Great! Go to the inventory
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
