import { useEffect, useRef, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { tg_haptic } from '../../utils/telegramapi';
import { Round } from '../../utils/math';
import { useGameStore, useUserStore } from '../../store';
import { InventoryWidget, MainNav, OverflowerText, Progressbar, SquadWidget, UpdateBarWidget } from '../../components';
import { FuelDrawer } from '../../components/FuelDrawer/FuelDrawer';
import { SESSION_STORAGE_DRAWER_KEY } from './Stage3';
import tgStar from '../../assets/icon-tg-star-gold.svg';
import { FightAnnouncement } from '../../components/FightWidgets/FightAnnouncement';
import WebsocketIndicator from "../../components/WebSocketContext/WebsocketIndicator";

export const Stage2 = () => {
  const userInfo = useUserStore((state) => state.user);
  const { incomingBattleState, gameMode, setGameMode } = useGameStore();
  const [isNeedShown, setIsNeedShown] = useState(false);
  const [isUpdateWidgetShown, setIsUpdateWidgetShown] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({ modifier: '', amount: 0 });
  const [isInventoryWidgetShown, setIsInventoryWidgetShown] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const [isFuelDrawerOpen, setIsFuelDrawerOpen] = useState(false);

  const fuelUp = () => {
    setIsFuelDrawerOpen(true);
  };

  const previousValues = useRef({
    hunger: userInfo.hunger,
    happiness: userInfo.happiness,
    health: userInfo.health,
  });

  useEffect(() => {
    if (userInfo.energy === null) {
      return;
    }

    const isShown = localStorage.getItem(SESSION_STORAGE_DRAWER_KEY);

    if (userInfo.energy > 0 && isShown) {
      localStorage.removeItem(SESSION_STORAGE_DRAWER_KEY);
    }

    if (userInfo.energy <= 1) {
      if (isShown) {
        return;
      }

      setIsFuelDrawerOpen(true);
      localStorage.setItem(SESSION_STORAGE_DRAWER_KEY, 'true');
    }
  }, [userInfo.energy]);

  useEffect(() => {
    if (!isInventoryWidgetShown && (userInfo.hunger < 1 || userInfo.health < 1 || userInfo.happiness < 1)) {
      const timeoutId = setTimeout(() => {
        if (userInfo.hunger < 1 || userInfo.health < 1 || userInfo.happiness < 1) {
          setIsNeedShown(true);
        }
      }, 1500);

      return () => clearTimeout(timeoutId);
    }
  }, [isNeedShown, isInventoryWidgetShown, gameMode, userInfo.hunger, userInfo.health, userInfo.happiness]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else if (gameMode !== 'normal') {
      let changedValue = null;
      if (userInfo.hunger !== previousValues.current.hunger) {
        changedValue = { modifier: 'hunger', amount: userInfo.hunger };
      } else if (userInfo.happiness !== previousValues.current.happiness) {
        changedValue = { modifier: 'happiness', amount: userInfo.happiness };
      } else if (userInfo.health !== previousValues.current.health) {
        changedValue = { modifier: 'health', amount: userInfo.health };
      }
      if (changedValue) {
        setUpdateInfo(changedValue);
        setIsUpdateWidgetShown(true);
        previousValues.current = {
          hunger: userInfo.hunger,
          happiness: userInfo.happiness,
          health: userInfo.health,
        };
      }
    }
  }, [userInfo.hunger, userInfo.happiness, userInfo.health, gameMode]);

  const tg = window.Telegram.WebApp;

  if (userInfo.energy === null) {
    return null;
  }

  return (
    <>
      <div className="w-full absolute mt-5">
        <SquadWidget squadInfo={userInfo.squad} />
        <Link
          to="/leaderbird"
          onClick={() => {
            tg_haptic.impactOccurred('light');
            tg.BackButton.show();
          }}
          className="flex flex-col items-center pointer-events-auto"
        >
          <div className="leaderbird w-max flex items-center justify-between text-white">
            <span className="nw-icon-wingl text-3xl text-white/[.6]"></span>
            <span className="mx-2 font-medium opacity-90">#{userInfo.rank}</span>
            <span className="nw-icon-wingr text-3xl text-white/[.6]"></span>
          </div>
          <div className="flex justify-between">
            <div className="text-white font-medium">
              🐣&nbsp;Owling Stage{' '}
              <span className="font-semibold text-sm text-white/[60%] ml-1">
                LVL <span className="text-white">{userInfo.level}</span>
              </span>
            </div>
            <span className="nw-icon-arrow -rotate-90 text-white/[.6] ml-2"></span>
          </div>
        </Link>
        <div className="w-full h-full left-0">
          <OverflowerText className="text-white absolute top-0" />
        </div>
        <div className="flex w-full mt-3">
          {isUpdateWidgetShown && <UpdateBarWidget modifier={updateInfo.modifier} amount={updateInfo.amount} />}
        </div>
        <Outlet />
      </div>
      <div className="w-full flex flex-col items-center">
        {isInventoryWidgetShown && (
          <InventoryWidget need="food" token={userInfo.token} setIsShown={setIsInventoryWidgetShown} />
        )}
        <div className="w-11/12 flex items-center justify-between bottom-[6.5rem] absolute flex-col">
          {userInfo.energy <= 50 && (
            <button
              className="mb-4 rounded-2xl py-3 px-4 space-x-2.5 flex w-fit bg-[#00000059] active:bg-[#0000008c]"
              onClick={fuelUp}
            >
              <div className="text-white text-[16px] flex font-semibold">⚡️ Fuel up</div>
              <div
                className="flex items-center justify-center space-x-1 py-[2px] px-[7px] rounded-[12px]"
                style={{
                  background:
                    'var(linear-gradient(130deg, rgba(253, 210, 26, 0.20) -21.52%, rgba(228, 123, 3, 0.20) 88.07%))',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <img src={tgStar} alt="tg-star" />
                <div
                  className="text-sm font-semibold"
                  style={{
                    color: '#FDD21A',
                  }}
                >
                  30
                </div>
              </div>
            </button>
          )}

          <div className="w-full flex items-center justify-between rounded-xl ">
            <div className="w-4/12 flex items-center">
              <span className="text-2xl">⚡</span>
              <span className="text-white font-medium ml-1 text-sm leading-3">
                <span className="text-xl font-bold">{userInfo.energy}</span>
                <br />
                <span className="opacity-70">/ 500</span>
              </span>
            </div>
            <Progressbar
              blurred
              winking={false}
              barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
              ht="min-h-3"
              percent="w"
              spark={false}
              value={userInfo.energy}
              maxvalue={500}
              customStyle={{ width: `${Round(userInfo.energy / 5, 1)}%` }}
            />
          </div>
        </div>
      </div>
      <MainNav
        skillPoints={userInfo.skillPoints}
        userExp={userInfo.totalExp}
        isBranch
        gameMode={gameMode}
        setIsNeedShown={setIsNeedShown}
        setGameMode={setGameMode}
      />
      <FuelDrawer isOpen={isFuelDrawerOpen} setIsOpen={setIsFuelDrawerOpen} />
      {incomingBattleState && <FightAnnouncement />}

      <WebsocketIndicator/>
    </>
  );
};
