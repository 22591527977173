import { InventoryInfo } from '../types/interface';
import { useMutation } from './useMutation';

export const useGetInventory = () => {
  const { mutate, ...rest } = useMutation<Array<InventoryInfo>>({
    url: 'inventory/',
    method: 'get',
  });

  return { getInventory: mutate, ...rest };
};
