import { useEffect, useRef, useState } from 'react';
import { useAnimations } from '@react-three/drei';
import { Object3D, Vector3 as ThreeVector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { LEVELS, STAGES } from '../../../config';
import { handleAnimation } from '../../../utils';
import { ENERGY_TO_REDUCE_ON_HOLD, useUserAction } from '../../../hooks';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem, QuarksUtil } from 'three.quarks';
import { ParticleSystemUtil } from '../particles/ParticleSystemUtil';
import { MODEL_URLS } from '../../../constants';
import { useGLTF } from '../hooks/useGLTF';
import { useUserStore } from '../../../store';
import { getLayerByRenderOrder } from './composition/Layer';
import { useBindObject3DToLayer } from '../hooks/useBindObject3DToLayer';

export type Coords = { x: number; y: number };

export const EggModel = () => {
  const { scene: mainScene } = useThree();
  const spark = useRef<Object3D>();
  const heat = useRef<Object3D>();
  const bgParticles = useRef<Object3D>();
  const levelUp = useRef<Object3D>();
  const batchedRenderer = useRef(new BatchedRenderer());
  const [particlesReady, setParticlesReady] = useState(false);
  const { scene, animations } = useGLTF(MODEL_URLS.egg);
  const { actions } = useAnimations(animations, scene);
  const { handleClickAction, handleHoldAction, handleHoldStopAction } = useUserAction('egg');
  const { user: userInfo, setLevel } = useUserStore();
  const energy = useUserStore((state) => state.user.energy);
  const { totalExp } = userInfo;

  const quarksEffectEgg = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise1_effect);
  const quarksEffectOwl = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise2_effect);

  const isNoHoldEnergy = energy === null || energy < ENERGY_TO_REDUCE_ON_HOLD;

  const handleClick = (event: MouseEvent) => {
    handleClickAction(event);
    handleAnimation({
      target: scene,
      model: 'egg',
      action: actions['Egg_locator_01|Take 001|BaseLayer'],
      animationName: 'anim_Tap_01',
      isLoop: false,
    });
    ParticleSystemUtil.playEffect('Spark', scene, spark.current, batchedRenderer.current);
  };

  useBindObject3DToLayer(batchedRenderer.current, getLayerByRenderOrder(0));

  const bgParticlesCurrentEffect = useRef<Object3D>();

  const handlePointerDown = (event: MouseEvent) => {
    event.stopPropagation();

    handleClick(event);
    handleHoldAction(heat.current, (time) => {
      if (time === 1) {
        bgParticlesCurrentEffect.current = ParticleSystemUtil.playEffect(
          'EggContinuousParticles',
          scene,
          bgParticles.current,
          batchedRenderer.current,
        );
      }
    });
  };

  const handlePointerMissed = () => {
    handleHoldStopAction(heat.current);
    if (bgParticlesCurrentEffect.current) {
      QuarksUtil.endEmit(bgParticlesCurrentEffect.current);
    }
  };

  const handlePointerUp = () => {
    handleHoldStopAction(heat.current);
    if (bgParticlesCurrentEffect.current) {
      QuarksUtil.endEmit(bgParticlesCurrentEffect.current);
    }
  };

  const handlePointerOut = () => {
    handleHoldStopAction(heat.current);
    if (bgParticlesCurrentEffect.current) {
      QuarksUtil.endEmit(bgParticlesCurrentEffect.current);
    }
  };

  useEffect(() => {
    if (isNoHoldEnergy) {
      handlePointerUp();
    }
  }, [handleHoldStopAction, isNoHoldEnergy]);

  useEffect(() => {
    if (!quarksEffectEgg || !quarksEffectOwl) {
      return;
    }

    spark.current = quarksEffectEgg.getObjectByName('Spark')!;
    heat.current = quarksEffectEgg.getObjectByName('EggHeat')!;
    bgParticles.current = quarksEffectEgg.getObjectByName('EggContinuousParticles')!;
    levelUp.current = quarksEffectOwl.getObjectByName('LevelUp')!;

    ParticleSystemUtil.prepareEffect('Spark', spark.current, new ThreeVector3(-0.2, -1.2, 0));
    ParticleSystemUtil.prepareEffect('LevelUp', levelUp.current);
    ParticleSystemUtil.prepareEffect('EggContinuousParticles', bgParticles.current, new ThreeVector3(-0.2, 0, 0), 2);

    heat.current.position.set(0, 5.5, 0);
    heat.current.traverse((obj: Object3D) => {
      if (obj instanceof ParticleEmitter) {
        batchedRenderer.current.addSystem(obj.system);
        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
      }
    });

    setParticlesReady(true);
  }, [quarksEffectOwl, quarksEffectEgg]);

  useEffect(() => {
    handleAnimation({
      target: scene,
      model: 'egg',
      action: actions['Egg_locator_01|Take 001|BaseLayer'],
      animationName: 'anim_Tap_02',
      isLoop: false,
    });
  }, [scene, actions]);

  useEffect(() => {
    if (totalExp >= STAGES[2]) {
      // Triggers manual hold stop action when stage changes
      handleHoldStopAction();
    }
  }, [handleHoldStopAction, totalExp]);

  useEffect(() => {
    if (!levelUp.current) {
      return;
    }

    let updatedLevel = userInfo.level;
    let deltaLevels = 0;

    if (userInfo.totalExp >= LEVELS[updatedLevel + 1]) {
      while (userInfo.totalExp >= LEVELS[updatedLevel + 1]) {
        updatedLevel += 1;
        deltaLevels += 1;
      }
    }

    setLevel(updatedLevel);

    for (let i = 0; i < deltaLevels; i++) {
      setTimeout(() => {
        ParticleSystemUtil.playEffect('LevelUp', mainScene, levelUp.current, batchedRenderer.current);
      }, i * 300);
    }
  }, [userInfo.totalExp, levelUp.current, particlesReady]);

  useFrame((_, delta) => {
    batchedRenderer.current.update(delta);
  });

  return (
    <primitive
      object={scene}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMissed={handlePointerMissed}
      onPointerOut={handlePointerOut}
    />
  );
};
