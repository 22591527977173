import { Drawer } from 'vaul';
import { useState } from "react";
import IconClose from '../../assets/icon-close-drawer.svg';
import { MissingStats } from './MissingStats';
import { Matchmaking } from './Matchmaking';

export const FightDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Drawer.Root dismissible={false} open={isOpen} onOpenChange={setIsOpen}>
      <Drawer.Trigger asChild>
        <button className="px-3 py-3 bg-[#352F3E7D] backdrop-blur font-semibold rounded-xl">Start battle</button>
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40"/>
        <Drawer.Content
          className="bg-[#1B181D] z-10 flex flex-col rounded-t-[10px] h-[56%] mt-24 fixed bottom-0 left-0 right-0">
          <div className="p-4 bg-[#1B181D] rounded-t-2xl flex-1">
            <div className="flex flex-col h-full justify-between">
              <div className="w-full flex justify-end">
                <button
                  className="rounded-full"
                  onClick={() => setIsOpen(false)}
                >
                  <img src={IconClose} alt={''}/>
                </button>
              </div>
              <MissingStats onCloseDrawer={() => setIsOpen(false)} />
              <Matchmaking onCloseDrawer={() => setIsOpen(false)} />
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
