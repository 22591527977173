import { useQuery } from './useQuery';
import { StoreGood } from '../types/interface';

export const useGetGoods = () => {
  const { data, ...rest } = useQuery<Array<StoreGood>>({
    url: 'goods/',
    method: 'get',
  });

  return { data, ...rest };
};
