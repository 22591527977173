import { PropsWithChildren, Suspense } from "react";

export const EntitySocket = ({
  children,
  position,
  rotation,
  scale,
}: PropsWithChildren<{
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: number;
}>) => {
  return (
    <Suspense fallback={null}>
      <group
        position={position}
        rotation={rotation}
        scale={scale}
      >
        {children}
      </group>
    </Suspense>
  );
};