export const RedStar = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.85067 1.81613C5.81299 0.861637 7.00709 0.402733 7.61835 1.13679L10.4128 4.49269C10.6869 4.82185 11.1402 4.93989 11.54 4.78623L15.6916 3.19074C16.6058 2.83938 17.4278 3.87569 16.8776 4.68597L14.469 8.2327C14.2185 8.60158 14.2425 9.09174 14.5279 9.43439L17.2387 12.6899C17.8561 13.4313 17.1692 14.5308 16.2322 14.301L11.8629 13.2298C11.4571 13.1304 11.0322 13.2936 10.7975 13.6393L8.27014 17.3609C7.72811 18.1591 6.4817 17.8026 6.44364 16.8386L6.27655 12.6055C6.25896 12.1599 5.94851 11.7799 5.51544 11.6737L1.35152 10.6529C0.400234 10.4196 0.316634 9.09954 1.23089 8.74818L5.38242 7.15269C5.78225 6.99903 6.03981 6.6078 6.02291 6.1798L5.85067 1.81613Z"
        fill="#FF5F82"
      />
    </svg>
  );
};
