import { useEffect } from 'react';
import { useGetGoods } from '../api';
import { useShopStore } from '../store';

export const useShopStoreInitializer = () => {
  const { data: storeGoods } = useGetGoods();
  const setStoreGoods = useShopStore((state) => state.setStoreGoods);

  useEffect(() => {
    if (storeGoods) {
      setStoreGoods(storeGoods);
    }
  }, [setStoreGoods, storeGoods]);
};
