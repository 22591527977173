import { Vector3 } from '@react-three/fiber/dist/declarations/src/three-types';
import { Camera, Size } from '@react-three/fiber';
import * as THREE from 'three';

type Params = {
  anchorVector: Vector3;
  camera: Camera & { manual?: boolean };
  offsetX?: number;
  offsetY?: number;
  size: Size;
};

export const getItemPosition = ({ anchorVector, camera, offsetX, offsetY, size }: Params) => {
  const vector = new THREE.Vector3(...(anchorVector as Array<number>));

  vector.setX(vector.x + (offsetX ?? 0));
  vector.setY(vector.y + (offsetY ?? 0));
  vector.project(camera);

  const x = (vector.x * 0.5 + 0.5) * size.width;
  const y = (1 - vector.y * 0.5 - 0.5) * size.height;

  return { x, y };
};
