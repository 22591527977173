import { QueryCallback, useMutation } from './useMutation';

export const useBuyGoods = (callback?: QueryCallback) => {
  const { mutate, ...rest } = useMutation({
    url: 'buy-goods/',
    method: 'post',
    ...callback,
  });

  return { buyGoods: mutate, ...rest };
};
