import { useEffect } from "react";
import { useWebSocketContext, WebSocketContext } from "../../WebSocketContext";
import { BattleState, useGameStore } from "../../../store";
import { IUserinfo } from "../../../types/interface";
import { MarkerPosition } from "./owlControls/useBattleOwlControls";

export const startOwlMatchmaking = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  difficulty?: 'easy' | 'medium' | 'hard'
) => {
  context.sendMessage(JSON.stringify({
    type: 'START_FIGHT',
    user_tgid: userInfo.tg_id,
    fight_type: difficulty ?? 'easy'
  }));
};

export const makeBattleChoice = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  attack: MarkerPosition | null,
  defence: MarkerPosition | null
) => {
  context.sendMessage(JSON.stringify({
    type: 'FIGHT_CHOICE',
    user_tgid: userInfo.tg_id,
    attack,
    defence,
  }));
};

export const useOwlBattleService = () => {
  const webSocketContext = useWebSocketContext();
  const { setBattleState, setIncomingBattleState } = useGameStore();
  const { lastMessage } = webSocketContext;

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    try {
      const message = JSON.parse(lastMessage.data);

      if (message.error) {
        setBattleState({ error: message.error } as BattleState);

        return;
      }

      if (message.type === 'FIGHT_UPDATE') {
        setBattleState(message.state);
      }

      if (message.type === 'WILL_START') {
        setIncomingBattleState(message.state);

        setTimeout(() => {
          setBattleState(message.state);
          setIncomingBattleState(null);
        }, 3000);
      }
    } catch {
      console.error('useOwlBattleService', 'failed to parse message', lastMessage);
    }
  }, [lastMessage]);

  return null;
};
