import { StoreGoodType } from '../../types/interface';
import { StoreItemType } from './StoreItemType';

type Props = {
  storeTypes: Array<StoreGoodType | 'all'>;
  selected: StoreGoodType | 'all';
  onChange: (tab: StoreGoodType) => void;
};

export const Tabs = ({ storeTypes, selected, onChange }: Props) => {
  return (
    <div className="flex justify-start flex-wrap">
      {storeTypes.map((type) => {
        return (
          <button key={type} onClick={() => onChange(type as StoreGoodType)}>
            <StoreItemType type={type} isActive={selected === type} />
          </button>
        );
      })}
    </div>
  );
};
