import { Box3, Object3D, Vector3 } from 'three';

export const getModelHeight = (modelRefCurrent: Object3D) => {
  const box = new Box3().setFromObject(modelRefCurrent);

  const size = new Vector3();
  box.getSize(size);

  return size.y;
};
