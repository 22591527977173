import { Html } from "@react-three/drei";
import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { Box3, Group, Object3D, Vector3 } from "three";
import { getModelSlotByMarkerPosition } from "./useBattleOwlControls";
import { useFrame } from "@react-three/fiber";
import { useBindObject3DToLayer } from "../useBindObject3DToLayer";
import { getLayerByRenderOrder } from "../../components/composition/Layer";
import { useUserStore } from "../../../../store";

export const useOwlNeedsControls = (enabled: boolean = false, {
  owl,
}: {
  owl: Object3D | null;
}) => {
  const userInfo = useUserStore((state) => state.user);
  const slotPositions = useRef<Record<string, Vector3>>({});
  const primaryIndicatorRef = useRef<Group>(null);
  const secondaryIndicatorRef = useRef<Group>(null);

  const need = useMemo(() => {
    const { hunger, happiness, health } = userInfo;

    if (hunger < 1) {
      return 'food';
    } else if (health < 1) {
      return 'health';
    } else if (happiness < 1) {
      return 'happiness';
    }

    return null;
  }, [userInfo]);

  const needIcon = useMemo(() => {
    if (!need) {
      return null;
    }

    // eslint-disable-next-line
    if (need === 'food') {
      return '🥐';
    } else if (need === 'health') {
      return '💊';
    } else if (need === 'happiness') {
      return '😥';
    }

    return null;
  }, [need]);

  const onNeedClick = (need: string) => {
    // TODO Handle fast-usage
  };

  useEffect(() => {
    if (!enabled || !owl) {
      return;
    }

    const slotMeshes = {
      top: owl.getObjectByName(getModelSlotByMarkerPosition('top')),
      center: owl.getObjectByName(getModelSlotByMarkerPosition('center')),
      bottom: owl.getObjectByName(getModelSlotByMarkerPosition('bottom')),
    };
    const box3 = new Box3();
    const center = new Vector3();

    for (const [slot, mesh] of Object.entries(slotMeshes)) {
      if (!mesh) {
        return;
      }

      box3.setFromObject(mesh);
      box3.getCenter(center);

      slotPositions.current[slot] = center.clone();

      owl.worldToLocal(slotPositions.current[slot]);
    }
  }, [owl, enabled]);

  useFrame(() => {
    if (
      !primaryIndicatorRef.current ||
      !secondaryIndicatorRef.current ||
      !slotPositions.current.top
    ) {
      return;
    }

    primaryIndicatorRef.current.position.copy(slotPositions.current.top);
    secondaryIndicatorRef.current.position.copy(slotPositions.current.top);

    primaryIndicatorRef.current.position.x += 3.0;
    primaryIndicatorRef.current.position.y += 4.0;

    secondaryIndicatorRef.current.position.x += 2.1;
    secondaryIndicatorRef.current.position.y += 2.2;
  });

  useBindObject3DToLayer(primaryIndicatorRef.current, getLayerByRenderOrder(1));
  useBindObject3DToLayer(secondaryIndicatorRef.current, getLayerByRenderOrder(1));

  if (!need || !needIcon || !enabled) {
    return {};
  }

  return {
    children: (
      <>
        <group ref={secondaryIndicatorRef}>
          <Html>
            <Container $size={0.4} $delayMs={300} />
          </Html>
        </group>
        <group ref={primaryIndicatorRef}>
          <Html onClick={() => {
            onNeedClick(need);
          }}>
            <Container $size={1.0}>
              <span>
                {needIcon}
              </span>
            </Container>
          </Html>
        </group>
      </>
    )
  };
};

const Container = styled.div<{
  $size?: number;
  $delayMs?: number;
}>`
  @keyframes indicatorAnimation {
    0% {
      transform: translate(-50%, -50%) scale(1.0);
    }

    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }

    100% {
      transform: translate(-50%, -50%) scale(1.0);
    }
  }

  position: relative;
  width: calc(50px * ${({ $size }) => $size});
  height: calc(50px * ${({ $size }) => $size});
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 100;
  animation: indicatorAnimation 2s infinite ease-in-out alternate both ${({ $delayMs }) => $delayMs || 0}ms;

  span {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    aspect-ratio: 1/1;
  }
`;
