import { useAnimations } from '@react-three/drei';
import { useEffect } from 'react';

import { useGameStore, useUserStore } from '../../../store';
import { useGLTF } from '../hooks/useGLTF';
import { MODEL_URLS } from '../../../constants';
import { useTreeSkins } from '../../../hooks/useTreeSkins';

export const TreeModel = () => {
  const { foreignOwl } = useGameStore();
  const activeUserExp = useUserStore((state) => state.user.totalExp);
  const currentExp = foreignOwl ? foreignOwl.experience : activeUserExp;

  const { scene, animations } = useGLTF(MODEL_URLS.tree);
  const { actions } = useAnimations(animations, scene);

  const inventory = useUserStore((state) => state.user.inventories);
  const equippedItems = inventory?.filter((item: any) => item.isEquipped);
  const equippedSkinDecorations = equippedItems?.filter((item: any) => item.good.type === 'decoration');
  useTreeSkins(scene, (foreignOwl ? foreignOwl?.items : equippedSkinDecorations) || [], currentExp);

  useEffect(() => {
    if (!actions) {
      return;
    }

    const actionKeys = Object.keys(actions);
    if (actionKeys.length > 0 && actions[actionKeys[0]]) {
      actions[actionKeys[0]]?.play(); // Play the first animation by default
    }
  }, [actions]);

  return (
    <primitive object={scene} />
  );
};
