import { useEffect } from 'react';
import * as THREE from 'three';

// NOTE Binds object to a selected rendering layer
//      Rendering layers ignore render order and apply one RT over another
//      without clearing the frame
export const useBindObject3DToLayer = (
  object: THREE.Object3D | null,
  layer: THREE.Scene | null,
) => {
  useEffect(() => {
    if (object) {
      object.removeFromParent();
    }

    if (!layer || !object) {
      return;
    }

    layer.add(object);
    
    return () => {
      if (object) {
        object.removeFromParent();
      }
    };
  }, [object, layer]);

  return null;
};
