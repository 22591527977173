import * as THREE from 'three';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

let layerMinOrder = Infinity;
let layerMaxOrder = -Infinity;
const layersByRenderOrder: Record<number, THREE.Scene> = {};

export const getLayerByRenderOrder = (renderOrder: number) => {
  return layersByRenderOrder[renderOrder];
};

export const Layer = ({
  renderOrder,
  children,
}: PropsWithChildren<{
  renderOrder: number;
}>) => {
  const { camera } = useThree();
  const layerRef = useRef<THREE.Scene>(null);

  useEffect(() => {
    layerMinOrder = Math.min(layerMinOrder, renderOrder);
    layerMaxOrder = Math.max(layerMaxOrder, renderOrder);
  }, []);

  useFrame(({ gl }) => {
    if (!layerRef.current) {
      return;
    }

    layersByRenderOrder[renderOrder] = layerRef.current;

    if (renderOrder === layerMinOrder) {
      gl.clearColor();
      gl.autoClear = false;
    }

    gl.clearDepth();
    gl.render(layerRef.current, camera);

    if (renderOrder === layerMaxOrder) {
      gl.autoClear = true;
    }
  }, renderOrder);

  return (
    <scene ref={layerRef}>
      {children}
    </scene>
  );
};
