import { tg_init } from '../utils/telegramapi';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../config';
import { useCallback, useEffect, useState } from 'react';
import {useGameStore, useUserStore} from '../store';

export const useTokenInitializer = () => {
  const setIsBlocked = useGameStore((state) => state.setIsBlocked);
  const setToken = useUserStore((state) => state.setToken);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initToken = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios<{ access_token: string }>({
        method: 'post',
        url: `${REACT_APP_BACKEND_URL}/token`,
        params: { initData: tg_init },
      });

      if (response.data && response.data.access_token) {
        setToken(response.data.access_token);
      } else {
        throw new Error('Token response is invalid');
      }
    } catch (err: any) {
      if(err.status === 404){
        setIsBlocked(true);
      }
      setError(err?.message || 'Unknown error');
    } finally {
      setIsLoading(false);
    }
  }, [setToken]);

  useEffect(() => {
    initToken();
  }, [initToken]);

  return { error, isLoading };
};
