import styled from "styled-components";
import { useGameStore } from '../../../store';
import { useEffect, useMemo, useState } from 'react';

export const Overlay = () => {
  const { battleState } = useGameStore();
  const [roundStart, setRoundStart] = useState<boolean>(false);
  const roundDeadline = useMemo(() => {
    if (!roundStart || !battleState) {
      return 0.0;
    }

    return battleState.deadline - Date.now();
  }, [battleState?.round, roundStart]);
  const roundLabel = useMemo<string | null>(() => {
    if (!battleState) {
      return null;
    }

    if (battleState.round === 0) {
      return 'Get Ready!';
    } else {
      return 'Round ' + (battleState.round + 1);
    }
  }, [battleState?.round, roundDeadline]);

  useEffect(() => {
    if (!battleState || !battleState.deadline) {
      return;
    }

    const deadline = battleState.deadline - Date.now();

    if (deadline < 10000) {
      setRoundStart(true);
    } else {
      setRoundStart(false);

      setTimeout(() => {
        setRoundStart(true);
      }, deadline - 10000);
    }
  }, [battleState?.round]);

  if (!battleState) {
    return null;
  }

  return (
    <Container>
      {!roundStart && roundLabel && (
        <RoundLabelContainer
          $labelDelayMs={battleState.round > 0 ? 3500 : 0}
        >
          <span className="mb-2 font-semibold text-xl text-center max-w-60 mx-auto text-white">
            {roundLabel}
          </span>
        </RoundLabelContainer>
      )}
      {roundStart && (
        <TimerContainer>
          <TimerProgress
            key={battleState.round}
            $timeMs={roundDeadline}
          >
            <TimerHighlight $visible />
          </TimerProgress>
          <TimerLabel>
            10s
          </TimerLabel>
        </TimerContainer>
      )}
    </Container>
  );
};

const RoundLabelContainer = styled.div<{
  $labelDelayMs?: number;
}>`
  @keyframes RoundLabelPopIn {
    0% {
      transform: translateX(-50%) translateY(-50%) scale(0);
      opacity: 0;
    }

    90% {
      transform: translateX(-50%) translateY(-50%) scale(1.2);
      opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(-50%) scale(1);
      opacity: 1;
    }
  }

  position: absolute;
  bottom: 150px;
  left: 50%;
  width: 300px;
  text-align: center;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  animation: RoundLabelPopIn .3s ${({ $labelDelayMs }) => $labelDelayMs ? `${($labelDelayMs / 1000.0).toFixed(2)}s` : ''} ease both alternate 1;
`;

const TimerLabel = styled.div`
  position: absolute;
  top: 50%;
  right: -36px;
  font-weight: 500;
  transform: translate(0, -50%);
  color: #ffffff;
  font-size: 14px;
`;

const TimerHighlight = styled.div<{
  $visible: boolean;
}>`
  @keyframes TimerHighlightFlicker {
    0% { opacity: 1.0; }
    10% { opacity: 0.82; }
    20% { opacity: 0.87; }
    30% { opacity: 0.85; }
    40% { opacity: 0.89; }
    50% { opacity: 0.81; }
    60% { opacity: 0.84; }
    70% { opacity: 0.81; }
    80% { opacity: 0.88; }
    90% { opacity: 0.82; }
  }

  position: absolute;
  top: 50%;
  right: -2px;
  transform: translate(0, -50%);
  width: 11px;
  height: 11px;
  background: #ffffff;
  border-radius: 50%;
  filter: blur(5px);
  z-index: 2;
  animation: TimerHighlightFlicker .6s infinite steps(2);
`;

const TimerProgress = styled.div<{
  $timeMs: number;
}>`
  @keyframes TimerProgress {
    from {
      width: 100%;
    }

    to {
      width: 0;
    }
  }

  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  animation: TimerProgress ${({ $timeMs }) => $timeMs / 1000.0}s linear both alternate 1;
`;

const TimerContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 8px;
  border-radius: 4px;
  background-color: #ffffff44;
  color: #ffffff;
  z-index: 1;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;
