import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import {
  BlueRectangle,
  BlueRing,
  PurpleCircle,
  PurpleRectangle,
  PurpleRing,
  RedRectangle,
  RedRing,
  RedStar,
  RedTriangle,
  YellowRectangle,
} from '../../assets/confetti';
import { createRoot } from 'react-dom/client';
import gsap from 'gsap';
import { Physics2DPlugin } from 'gsap/Physics2DPlugin';

gsap.registerPlugin(Physics2DPlugin);

const shapes = [
  BlueRing,
  BlueRectangle,
  PurpleRectangle,
  PurpleRing,
  PurpleCircle,
  RedRing,
  RedRectangle,
  RedStar,
  RedTriangle,
  YellowRectangle,
];

const getRandom = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const CONFETTI_NUMBER = 50;
const SHOOT_TIMES = 3;
const SHOOT_INTERVAL = 400;
const SPEED = 1300;
const ANGLE = -85;
const SPREAD = 75;
const GRAVITY = 1200;
const DURATION = 5;

type Props = {
  imgRef: RefObject<HTMLImageElement>;
};

export const Confetti = ({ imgRef }: Props) => {
  const confettiRef = useRef<HTMLDivElement>(null);

  const shootConfetti = useCallback(() => {
    const confettiElements: HTMLDivElement[] = [];

    if (confettiRef.current) {
      if (!imgRef.current) {
        return;
      }

      const startX = window.innerWidth / 2;
      const startY = imgRef.current.getBoundingClientRect().top;

      for (let i = 0; i < CONFETTI_NUMBER; i++) {
        const confettiWrapper = document.createElement('div');
        confettiWrapper.classList.add('confetti');
        confettiWrapper.style.position = 'absolute';
        confettiWrapper.style.width = 'auto';
        confettiWrapper.style.height = 'auto';
        confettiWrapper.style.transformStyle = 'preserve-3d';

        confettiWrapper.style.left = `${startX - 10}px`;
        confettiWrapper.style.top = `${startY - 15}px`;

        const svgContainer = document.createElement('div');
        confettiWrapper.append(svgContainer);

        const RandomSVG = shapes[Math.floor(Math.random() * shapes.length)];
        const root = createRoot(svgContainer);
        root.render(<RandomSVG />);

        confettiRef.current.append(confettiWrapper);
        confettiElements.push(confettiWrapper);
      }

      confettiElements.forEach((confetti) => {
        const minAngle = ANGLE - SPREAD / 2;
        const maxAngle = ANGLE + SPREAD / 2;

        const minVelocity = SPEED / 3;
        const maxVelocity = SPEED;

        const velocity = getRandom(minVelocity, maxVelocity);
        const angle = getRandom(minAngle, maxAngle);
        const gravity = GRAVITY;

        gsap.to(confetti, {
          physics2D: {
            angle,
            velocity,
            gravity,
          },

          rotationX: getRandom(720, 1440),
          rotationZ: getRandom(720, 1440),
          duration: DURATION,
          ease: 'power1.out',
          onComplete: () => {
            confetti.remove();
          },
        });
      });
    }

    setTimeout(() => {
      confettiElements.forEach((confetti) => confetti.remove());
    }, DURATION * 1000);
  }, [imgRef]);

  useEffect(() => {
    let shootCount = 0;

    const intervalId = setInterval(() => {
      if (shootCount < SHOOT_TIMES) {
        shootConfetti();
        shootCount++;
      } else {
        clearInterval(intervalId);
      }
    }, SHOOT_INTERVAL);

    return () => clearInterval(intervalId);
  }, [shootConfetti]);

  return (
    <div
      ref={confettiRef}
      className="confetti-container"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none',
        overflow: 'hidden',
        zIndex: 1,
      }}
    ></div>
  );
};
