import { REACT_APP_BACKEND_URL } from '../config';
import axios from 'axios';

export type ActionType = 'click' | 'hold' | 'swipe';

export type ActionNumber = Partial<Record<'clicks' | 'holds' | 'swipes', number>>;

export type Mode = 'egg' | 'owl' | 'dummy';

export type Action = {
  start: string;
  end: string;
} & ActionNumber;

type Input = {
  type: ActionType;
  mode: Mode;
  item_id?: number;
  token: string;
  actions: Array<Action>;
  callback?: () => void;
};

export const performAction = ({ token, callback, ...data }: Input) => {
  try {
    axios({
      method: 'post',
      url: `${REACT_APP_BACKEND_URL}/userAction/`,
      data,
      headers: { Authorization: `Bearer ${token}` },
    }).finally(callback);
  } catch (error) {
    console.error(error);
  }
};
