import useAnimation from '../../hooks/useAnimation';
import { StoreGood } from '../../types/interface';
import WebApp from '@twa-dev/sdk';
import { EggContentScreen } from './EggContentScreen';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUseItem } from '../../api';
import IconClose from '../../assets/icon-close.svg';
import { MainButton } from '../../components';
import { Confetti } from './Confetti';

type Props = {
  onCloseClick: () => void;
  purchasedGood: StoreGood;
};

export const SuccessfulPurchaseScreen = ({ onCloseClick, purchasedGood }: Props) => {
  const navigate = useNavigate();

  const imgRef = useRef(null);

  const elementRef = useAnimation({
    from: { opacity: 0, y: 20 },
    to: { duration: 1, opacity: 1, y: 0, ease: 'power3.out', stagger: 0.2 },
  });

  const [isEggContentShown, setIsEggContentShown] = useState(false);

  const { applyItem } = useUseItem({
    itemId: purchasedGood.id,
    onSuccess: () => {
      setIsEggContentShown(true);
    },
  });

  const isEgg = purchasedGood.type === 'egg';

  WebApp.BackButton.onClick(() => {
    onCloseClick();
  });

  if (isEggContentShown) {
    return <EggContentScreen onCloseClick={() => setIsEggContentShown(false)} />;
  }

  return (
    <>
      <Confetti imgRef={imgRef} />
      <div className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 bg-[#080D1B]">
        <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
        <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 bottom-0 rounded-[50%] z-0"></div>

        <div
          className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 px-5 pt-6"
          style={{
            backdropFilter: 'blur(36px) brightness(70%)',
          }}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="w-full h-full flex flex-col" ref={elementRef}>
              <button
                className="w-9 h-9 mb-3 pointer-events-auto bg-center bg-cover self-end"
                onClick={onCloseClick}
                style={{ backgroundImage: `url("${IconClose}")` }}
              />

              <div className="flex-1 relative flex flex-col justify-center items-center gap-6 px-2  mb-16">
                <div
                  className="absolute rounded-[312px] bg-[#FFD324] w-[312px] h-[312px] z-0 mb-28"
                  style={{ filter: 'blur(72.6px)' }}
                />
                <div className="absolute flex flex-col w-full items-center">
                  <img
                    ref={imgRef}
                    className="w-full aspect-square rounded-[36px] bg-center bg-cover bg-no-repeat max-w-[256px] z-1"
                    id="shop-item-image"
                    src={purchasedGood.img}
                    alt="item"
                  />

                  <p className="text-md font-normal text-white text-center mt-6">Congratulations! You’ve got</p>
                  <h4 className="text-2xl font-bold text-white text-center mb-1 leading-[64px]">
                    {purchasedGood.name}
                  </h4>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-2 z-10 mb-6 ">
              <MainButton
                isLoading={false}
                btnText={isEgg ? "Look what's inside" : 'Feed your owl'}
                onClick={isEgg ? applyItem : () => navigate('/profile')}
              />

              {!isEgg && (
                <button
                  type="button"
                  onClick={() => navigate('/store')}
                  className="pointer-events-auto w-full text-white font-medium rounded-lg text-sm px-5 py-3 text-center"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.14)',
                    backdropFilter: 'blur(11.14px)',
                  }}
                >
                  Back to store
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
