import { InventoryInfo } from '../types/interface';
import { useQuery } from './useQuery';

export const useGetInventoryQuery = () => {
  const { data, ...rest } = useQuery<Array<InventoryInfo>>({
    url: 'inventory/',
    method: 'get',
  });

  return { inventory: data, ...rest };
};
