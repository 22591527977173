
// NOTE Add all global scene conditions here
//      Ex. lights, background, environment
export const Environment = () => {
  return (
    <>
      <hemisphereLight args={['#fff8f2', '#ffe2d2', 1.4]} />
      <directionalLight
        castShadow={false}
        color="#ffe2d2"
        position={[10, 20, 10]}
        intensity={2}
      />
    </>
  )
};
