import { useCallback, useEffect, useRef } from 'react';
import { useUserStore } from '../store';

const UPDATE_INTERVAL = 60000; //180000;

export const useTemperature = () => {
  const fetchedLastHeating = useUserStore((state) => state.user.fetchedLastHeating);
  const hastHeating = useUserStore((state) => state.user.last_heating);
  const temperature = useUserStore((state) => state.user.temperature);
  const isZeroTemperature = temperature === 0;
  const isTemperatureFetched = temperature !== null;

  const decreaseTemperature = useUserStore((state) => state.decreaseTemperature);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const updateTemperature = useCallback(() => {
    const tempToDecrease = 1;
    decreaseTemperature(tempToDecrease);
  }, [decreaseTemperature]);

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isZeroTemperature || !isTemperatureFetched) {
      clearCurrentInterval();
      return;
    }

    const fetchedLastHeatingDate = fetchedLastHeating ? new Date(fetchedLastHeating) : null;

    const heatPassedRemainder = fetchedLastHeatingDate
      ? (Date.now() - fetchedLastHeatingDate.getTime()) % UPDATE_INTERVAL
      : null;

    const delay = heatPassedRemainder === null ? null : UPDATE_INTERVAL - heatPassedRemainder;

    const startInterval = () => {
      clearCurrentInterval();

      intervalRef.current = setInterval(updateTemperature, UPDATE_INTERVAL);
    };

    if (!timeoutRef.current && delay !== null) {
      timeoutRef.current = setTimeout(() => {
        updateTemperature();
        startInterval();
      }, delay);
    } else {
      startInterval();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [updateTemperature, fetchedLastHeating, hastHeating, isTemperatureFetched, isZeroTemperature]);
};
