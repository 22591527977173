import { useEffect, useMemo, useState } from 'react';
import VSicon from '../../../assets/vs.png'
import { useGameStore } from '../../../store';
import {Progressbar} from "../../Progressbar";
import { FightResult } from '../FightResult';
import { useWebSocketContext } from '../../WebSocketContext';

export const Header = () => {
  const { battleState } = useGameStore();
  const [showFightResult, setShowFightResult] = useState(false);
  const { lastMessage } = useWebSocketContext();

  const lastMessageIsFightEnd = useMemo(() => {
    if (!lastMessage) {
      return false;
    }

    try {
      const messageContent = JSON.parse(lastMessage.data);

      return messageContent.type === 'FIGHT_END';
    } catch {
      return false;
    }
  }, [lastMessage]);
  const isFightFinished = battleState ? battleState.user1.hp <= 0 || battleState.user2.hp <= 0 || lastMessageIsFightEnd : false;

  useEffect(() => {
    if (isFightFinished && !showFightResult) {
      setTimeout(() => {
        setShowFightResult(true);
      }, 4000);
    }
  }, [isFightFinished]);

  if (!battleState) {
    return null;
  }

  if (showFightResult) {
    return <FightResult />;
  }

  return (
    <div className="absolute top-0 z-5 w-full pt-5 px-5 flex justify-between items-center bg-gradient-to-b from-black to-transparent">
      <div className="user flex">
        <div className="w-10 min-w-10 h-10 p-0.5 bg-gradient-to-b from-[#F2AB85] to-[#F3C751] rounded-full">
          <img src={"https://cdn.notwise.co/445782050.jpeg"} alt={''} className="w-full h-auto rounded-full"/>
        </div>
        <div className="flex flex-col text-white font-semibold ml-3">
          <span className="mb-1">You</span>
          <Progressbar
            blurred
            winking={false}
            barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
            ht="min-h-2"
            percent="w"
            spark={false}
            value={battleState.user1.hp}
            maxvalue={20}
            customStyle={{
              width: `${Math.round((battleState.user1.hp / 20) * 100)}%`
            }}
          />
          <span className="mt-2">
            💛&nbsp; {battleState.user1.hp}<span className="text-white/[.6]"> / 20</span>
          </span>
        </div>
      </div>
      <div>
        <img src={VSicon} alt="vsIcon" className="w-8"/>
      </div>
      <div className="enemy flex">
        <div className="w-10 min-w-10 h-10 p-0.5 bg-gradient-to-b from-[#F2AB85] to-[#F3C751] rounded-full">
          <img src={"https://cdn.notwise.co/445782050.jpeg"} alt={''} className="w-full h-auto rounded-full"/>
        </div>
        <div className="flex flex-col text-white font-semibold ml-3">
          <span className="mb-1">Enemy</span>
          <Progressbar
            blurred
            winking={false}
            barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
            ht="min-h-2"
            percent="w"
            spark={false}
            value={battleState.user2.hp}
            maxvalue={20}
            customStyle={{
              width: `${Math.round((battleState.user2.hp / 20) * 100)}%`
            }}
          />
          <span className="mt-2">
            💛&nbsp; {battleState.user2.hp}<span className="text-white/[.6]"> / 20</span>
          </span>
        </div>
      </div>
    </div>
  );
};
