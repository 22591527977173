import React, { useEffect, useRef } from 'react';
import { gsap, TimelineMax, RoughEase, Linear } from 'gsap/all';
interface StarfieldProps {
  amount: number;
}

const Starfield: React.FC<StarfieldProps> = ({amount}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const numAnimations = 50;
  const numStars = amount;
  const vw = window.innerWidth;
  const vh = window.innerHeight - 10;
  const appearMin = 0.3;
  const appearMax = 0.8;
  const delayMin = 2;
  const delayMax = 6;
  const durationMin = 0.3;
  const durationMax = 1;

  const textures = [
    'https://cdn.notwise.co/star1.png',
    'https://cdn.notwise.co/star2.png',
    'https://cdn.notwise.co/star3.png',
    'https://cdn.notwise.co/star4.png',
  ];

  const random = (min: number, max?: number) => {
    if (max == null) {
      max = min;
      min = 0;
    }
    if (min > max) {
      const temp = min;
      min = max;
      max = temp;
    }
    return min + (max - min) * Math.random();
  };

  const eases = Array.from({ length: numAnimations }, () =>
    RoughEase.ease.config({
      template: Linear.easeNone,
      strength: random(1, 3),
      points: Math.trunc(random(50, 200)),
      taper: "both",
      randomize: true,
      clamp: true
    })
  );

  useEffect(() => {
    if (mainRef.current) {
      const stars = Array.from({ length: numStars }, () => createStar());
      stars.forEach(star => mainRef.current?.appendChild(star.element));
    }
  }, []);

  const createStar = () => {
    const index = Math.trunc(random(textures.length));
    const starElement = document.createElement('img');
    starElement.src = textures[index];
    starElement.classList.add('star');
    starElement.style.position = 'absolute';

    gsap.set(starElement, {
      xPercent: -50,
      yPercent: -50,
      scale: 0,
      x: random(vw),
      y: random(vh),
    });

    const tl = new TimelineMax({ repeat: -1, yoyo: true });

    for (let i = 0; i < numAnimations; i++) {
      const ease1 = eases[Math.trunc(random(numAnimations))];
      const ease2 = eases[Math.trunc(random(numAnimations))];

      const alpha = random(0.7, 1);
      const scale = random(0.15, 0.4);

      const appear = `+=${random(appearMin, appearMax)}`;
      const delay = `+=${random(delayMin, delayMax)}`;
      const duration1 = random(durationMin, durationMax);
      const duration2 = random(durationMin, durationMax);

      tl.to(starElement, duration1, { autoAlpha: alpha, scale: scale, ease: ease1 }, delay)
        .to(starElement, duration2, { autoAlpha: 0, scale: 0, ease: ease2 }, appear);
    }

    tl.progress(random(1));

    return {
      element: starElement,
      timeline: tl
    };
  };

  return <div ref={mainRef} className="starfield" />;
};

export default Starfield;
