import {useNavigate} from 'react-router-dom';
import { StoreGood } from '../../types/interface';
import { useEffect, useState } from 'react';

const STAR_ICON_URL = 'https://notwise.b-cdn.net/icons/stars.svg';
const COIN_ICON_URL = 'https://notwise.b-cdn.net/icons/coin-icon.png';

const formatTime = (time: number) => {
  const remainingSeconds = time % 3600;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return `${hours}:${minutes}:${seconds}`;
};

type ItemProps = {
  good: StoreGood;
};

export const StoreItem = ({ good: { id, type, img, stars_price, price, duration } }: ItemProps) => {
  const isDaily = duration !== null;
  const tg = window.Telegram.WebApp;
  const navigate = useNavigate();
  const [time, setTime] = useState(duration);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!time) {
      return;
    }

    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [isDaily, time]);

  return (
    <div
      className={`flex flex-col justify-center items-center rounded-full pointer-events-auto ${
        isDaily ? 'border-[#FFD324]' : 'border-transparent'
      }`}
    >
      <span onClick={()=> {tg.BackButton.show(); navigate(`/store/${id}`)}}
        className={`relative flex w-full aspect-[1/1] justify-center rounded-2xl bg-transparent mb-2 pointer-events-auto ${
          isDaily ? 'border-[#FFD324] border-[1px]' : ''
        }`}
      >
        <img
          className="w-full h-full  rounded-2xl"
          src={img}
          alt="item"
          rounded-2xl
          onLoad={() => setIsLoading(false)}
        />
        {isLoading && <div className="absolute top-0 left-0 w-full h-full bg-slate-700 animate-pulse rounded-2xl" />}
        {time && (
          <div className="w-[62px] absolute bottom-1.5 rounded-2xl bg-[#176EFF] font-semibold py-0.5 px-2 text-xs text-left text-white">
            {formatTime(time)}
          </div>
        )}
      </span>
      <div className="flex justify-center items-center">
        <img alt="img" src={stars_price ? STAR_ICON_URL : COIN_ICON_URL} width={18} height={18} />
        <p className="text-sm leading-3 font-bold text-white ml-1.5">{stars_price ? stars_price : price}</p>
      </div>
    </div>
  );
};
