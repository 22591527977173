import WebApp from '@twa-dev/sdk';
import { useState } from 'react';
import { useUserStore } from '../../store';
import coinIcon from '../../assets/nyansapo-big.svg';
import referals from '../../assets/referals.svg';
import toast from 'react-hot-toast';
import { Tabs } from './Tabs';
import { Fren } from './Fren';
import IconLeft from '../../assets/icon-left.svg';
import { useNavigate } from 'react-router-dom';
import { useGetFrens } from '../../api';
import Starfield from "../../components/Starfield";
import {useNavigateByExp} from "../../hooks";

export const Frens = () => {
  const userInfo = useUserStore((state) => state.user);
  const navigateByExp = useNavigateByExp();

  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const [isCoinLoading, setIsCoinLoading] = useState(true);
  const [isReferalsLoading, setIsReferalsLoading] = useState(true);

  const { data, isLoading } = useGetFrens();

  const copyReferalLink = () => {
    navigator.clipboard.writeText(`https://t.me/notwise_bot?start=${userInfo.tg_id}`);
    toast.success('Link has been copied', {icon: "🔗", style: {
        borderRadius: '30px',
        background: '#090e1de3',
        color: '#fff',
      }});
  };

  const hasFrens = userInfo.hasFrens;

  const frens = data?.frens;

  const tabs = [
    { title: `${frens?.length} Frens`, isActive: activeTabIdx === 0 },
    { title: 'How it works', isActive: activeTabIdx === 1 },
  ];

  WebApp.BackButton.onClick(() => {
    WebApp.BackButton.hide();
    navigateByExp(userInfo.totalExp);
  });

  return (
    <div className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-auto no-scrollbar pointer-events-auto">
      <Starfield amount={100}/>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-12 rounded-[50%] z-0"></div>

      <div className="flex flex-col items-center mt-7 z-10 h-full px-10">
        {isCoinLoading && <div className="w-[63px] h-[63px] animate-pulse rounded-full bg-slate-700 mt-[58px]" />}
        <img
          className="w-[68px] h-[68px] mt-[58px]"
          src={coinIcon}
          alt="icon"
          onLoad={() => setIsCoinLoading(false)}
          style={{
            display: isCoinLoading ? 'none' : 'block',
          }}
        />

        <div className="text-2xl font-bold text-white mt-4">Invite frens</div>

        <div className="text-[#E3EAF699] text-center flex flex-col items-center">
          <div>Earn more Wise Coins</div>
          <div>when your referrals level up</div>
        </div>
        {!hasFrens ? (
          <>
            {isReferalsLoading && (
              <div className="w-full animate-pulse aspect-[330/181] flex flex-col mt-16 mr-[3vw]">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="rounded-full w-[6.5vw] h-[6.5vw] bg-slate-700" />
                    <div className="w-[7vw] h-[2vw] bg-slate-700 mt-2" />

                    <div className="w-[8.5vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[6.6vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[9vw] h-[2vw] bg-slate-700 mt-4" />
                  </div>

                  <div className="w-[10vw] h-[2px] bg-slate-700 self-start mt-[3.25vw]" />

                  <div className="flex flex-col">
                    <div className="rounded-full w-[6.5vw] h-[6.5vw] bg-slate-700" />
                    <div className="w-[7vw] h-[2vw] bg-slate-700 mt-2" />

                    <div className="w-[8.5vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[6.6vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[9vw] h-[2vw] bg-slate-700 mt-4" />
                  </div>

                  <div className="w-[10vw] h-[2px] bg-slate-700 self-start mt-[3.25vw]" />

                  <div className="flex flex-col">
                    <div className="rounded-full w-[6.5vw] h-[6.5vw] bg-slate-700" />
                    <div className="w-[7vw] h-[2vw] bg-slate-700 mt-2" />

                    <div className="w-[8.5vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[6.6vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[9vw] h-[2vw] bg-slate-700 mt-4" />
                  </div>

                  <div className="w-[10vw] h-[2px] bg-slate-700 self-start mt-[3.25vw]" />

                  <div className="flex flex-col">
                    <div className="rounded-full w-[6.5vw] h-[6.5vw] bg-slate-700" />
                    <div className="w-[7vw] h-[2vw] bg-slate-700 mt-2" />

                    <div className="w-[8.5vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[6.6vw] h-[2vw] bg-slate-700 mt-4" />
                    <div className="w-[9vw] h-[2vw] bg-slate-700 mt-4" />
                  </div>
                </div>
              </div>
            )}
            <img
              src={referals}
              alt="referals"
              className="w-full h-auto mt-12"
              onLoad={() => setIsReferalsLoading(false)}
              style={{
                display: isReferalsLoading ? 'none' : 'block',
              }}
            />
          </>
        ) : (
          <>
            <div className="mt-8">
              <Tabs items={tabs} onSelect={setActiveTabIdx} />
            </div>
            <div className="w-full mt-6">
              {activeTabIdx === 0 && (
                <>
                  <div className="w-full flex justify-between text-xs text-[#E3EAF699] mb-5">
                    <div>Name</div>
                    <div>Earned</div>
                  </div>
                  <div className="flex flex-col space-y-6">
                    {isLoading
                      ? [1, 2, 3].map((item, idx) => <Fren key={idx} isLoading />)
                      : frens?.map(({ username, coins, avatar, frens_count, tg_id }, idx) => (
                          <Fren
                            key={idx}
                            tg_id={tg_id}
                            name={username}
                            coins={coins}
                            avatar={avatar ?? 'https://notwise.b-cdn.net/icons/coin-icon.png'}
                            frenCount={frens_count ?? 0}
                          />
                        ))}
                  </div>
                </>
              )}
              {activeTabIdx === 1 && <img src={referals} alt="referals" className="w-full h-auto" />}
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col space-y-3 pt-4 pb-14 px-5">
        <button
          type="button"
          className="rounded-lg bg-[#ffffff14] text-white p-2.5 h-12 w-full text-center font-semibold active:bg-[#a2a2a214]"
          onClick={copyReferalLink}
        >
          Copy referral link
        </button>
        <button
          type="button"
          className="rounded-lg bg-white text-black p-2.5 h-12 w-full text-center active:bg-gray-200"
        >
          Invite frens
        </button>
      </div>
    </div>
  );
};
