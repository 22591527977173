import styled from "styled-components";
import { useGameStore } from "../../../store";
import { BattleIcon } from "../../FightDrawer/BattleIcon";
import { useGetEnemyInfo } from "../../../api/useGetEnemyInfo";
import { Progressbar } from "../../Progressbar";

export const FightAnnouncement = () => {
  const { incomingBattleState } = useGameStore();
  const enemyInfo = useGetEnemyInfo({ id: incomingBattleState?.user1.tgid! });

  if (!incomingBattleState || enemyInfo.isLoading) {
    return null;
  }

  return (
    <Container>
      <AnnouncementLoader>
        <BattleIcon />
      </AnnouncementLoader>
      <AnnouncementDetails>
        <UserIcon>
          <img alt="img" src="/assets/images/avatar.png" width={100} height="auto" />
        </UserIcon>
        <Label>
          <div className="text-white font-medium">{enemyInfo.userInfo.username} challenged you ⚔️</div>
          <div className="font-regular text-sm text-white/[60%]">
            {enemyInfo.userInfo.username}'s Squad
          </div>
        </Label>
        <Timer>
          <div className="text-white text-xl font-medium">3s</div>
          <div className="font-regular text-xs text-white/[60%]">
            to fight
          </div>
        </Timer>
        <DeadlineProgress>
          <Progressbar
            blurred
            winking={false}
            barcolor="bg-gradient-to-r from-[#FFAD42] to-[#FFE34F]"
            ht="min-h-1"
            percent="w"
            spark={false}
            value={100}
            maxvalue={100}
            customStyle={{
              width: `100%`,
            }}
          />
        </DeadlineProgress>
      </AnnouncementDetails>
    </Container>
  );
};

const DeadlineProgress = styled.div`
  @keyframes DeadlineProgressScaling {
    0% {
      width: calc(100% - 28px);
    }

    100% {
      width: 0;
    }
  }

  position: absolute;
  bottom: 4px;
  left: 14px;
  width: calc(100% - 28px);
  z-index: 1;
  animation: DeadlineProgressScaling 3s 1.5s ease-in 1 both;
`;

const AnnouncementLoader = styled.div`
  @keyframes AnnouncementLoaderPopIn {
    0% {
      opacity: 0.0;
      transform: translate(-50%, -50%) scale(0.75);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }

    20% {
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(2.0);
    }

    80% {
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(1.0) rotate(-30deg);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }

    100% {
      opacity: 0.0;
      transform: translate(-50%, -50%) scale(20.0) rotate(180deg);
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: AnnouncementLoaderPopIn 1.5s ease both 1;
  pointer-events: none;
`;

const AnnouncementDetails = styled.div`
  @keyframes AnnouncementDetailsPopIn {
    0% {
      opacity: 0.0;
    }

    100% {
      opacity: 1.0;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: AnnouncementDetailsPopIn 0.6s 1.3s ease both 1;
`;

const Timer = styled.div`
  flex-shrink: 0;
  padding: 16px;
  text-align: center;
`;

const Label = styled.div`
  flex: 1;
  padding: 16px 0;
`;

const UserIcon = styled.div`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background: #111111;
  border-radius: 50%;
  margin: 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;  
  }
`;

const Container = styled.div`
  @keyframes ContainerBorderPopIn {
    0% {
      border-color: #ffffff00;
    }

    50% {
      border-color: #ffffffff;
    }

    100% {
      border-color: #ffffff00;
    }
  }

  position: fixed;
  bottom: 128px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  min-height: 80px;
  background: #D9D9D960;
  border: 4px solid #ffffff00;
  border-radius: 0.75rem;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 100;
  overflow: hidden;
  animation: ContainerBorderPopIn 1.0s 1.2s ease both 1;
`;