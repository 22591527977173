import { Drawer } from 'vaul';
import IconClose from '../../assets/icon-close-drawer.svg';
import { MainButton } from '../MainButton';
import tgStar from '../../assets/icon-tg-star.svg';
import { useRefillEnergy } from '../../api/useRefillEnergy';
import WebApp from '@twa-dev/sdk';
import toast from 'react-hot-toast';
import { useUserStore } from '../../store';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const FuelDrawer = ({ isOpen, setIsOpen }: Props) => {
  const { refillEnergy } = useRefillEnergy();
  const energy = useUserStore((state) => state.user.energy);
  const addEnergy = useUserStore((state) => state.addEnergy);

  const fuelUp = async () => {
    const link = await refillEnergy();

    if (link) {
      WebApp.openInvoice(link, (status) => {
        if (status === 'paid') {
          energy !== null && addEnergy(500 - energy);
          toast('Energy has been refilled',{icon: '👏', style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
          }});
        }

        if (status === 'failed') {
          toast.error('Payment failed!', {style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
            }});
        }
      });
    }

    setIsOpen(false);
  };

  return (
    <Drawer.Root dismissible={false} open={isOpen} onOpenChange={setIsOpen}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-[#13161B] z-10 flex flex-col rounded-t-[10px] h-[56%] mt-12 mb-5 fixed bottom-0 left-0 right-0">
          <div className="flex flex-col p-4 rounded-t-2xl h-full">
            <div className="w-full flex justify-end">
              <button className="rounded-full" onClick={() => setIsOpen(false)}>
                <img src={IconClose} alt={''} />
              </button>
            </div>
            <div className="flex flex-col justify-center flex-1 items-center">
              <div className="flex flex-col flex-1 justify-center items-center relative">
                <div className="text-[73.16px]">🪫</div>
                <div className="text-[26px] mt-[5px] text-white font-semibold text-center">Out of Energy, Fren</div>
                <div className="text-[16px] text-[#ffffff80] mt-3 text-center">
                  It'll refill fully in 15 minutes, but we’ve got a quick boost for you!
                </div>
              </div>

              <MainButton
                isLoading={false}
                btnText={
                  <div className="flex space-x-2.5 justify-center items-center">
                    <div>Fuel up</div>
                    <div
                      className="flex items-center space-x-1 py-[2px] px-[7px] rounded-[14px]"
                      style={{
                        background: 'linear-gradient(151deg, #FDCB81 -19.69%, #C55900 103.74%)',
                        backdropFilter: 'blur(12px)',
                      }}
                    >
                      <img src={tgStar} alt="tg-star" />
                      <div>30</div>
                    </div>
                  </div>
                }
                onClick={fuelUp}
              />
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
