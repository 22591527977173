import ReactDOM from 'react-dom/client';
import './styles/tailwind.css';
import './styles/custom.css';
import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import React from "react";
// import "./theme/global.scss";
const tg = window.Telegram.WebApp;
tg.ready();
tg.expand();
tg.disableVerticalSwipes();
// if (process.env.REACT_APP_ENV_DEV !== "true") {
//    tg.showAlert(tg.platform);
// }
/// /tg.disableVerticalSwipes(); Not applicable yet

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
