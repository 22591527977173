import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useGameStore } from '../../store';
import { Progressbar } from '../Progressbar';

interface LoadingType {
  className?: string;
  children: React.ReactNode;
  // percent: number;
}

const LazyWrap = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity 0.5s ease-in-out;
`;
const Sky = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  background: rgba(29, 135, 204, 1);
  background: linear-gradient(0deg, rgba(198, 239, 251, 1) 0%, rgba(117, 222, 251, 1) 48%, rgba(29, 135, 204, 1) 100%);
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
const Logo = styled.div`
  position: absolute;
  top: 0;
  z-index: 4;
  background: url('/assets/images/notwise-logo.svg') no-repeat center 30%;
  background-size: 70%;
  height: 100dvh;
  width: 100dvw;
  pointer-events: none;
`;
const Clouds = styled.div`
  position: absolute;
  top: 0;
  z-index: 3;
  background: url('/assets/images/clouds.png') no-repeat 0 30%;
  background-size: 250%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  animation-name: load;
  animation-duration: 62s;
  @keyframes load {
    0% {
      background-position-x: 30%;
    }
    100% {
      background-position-x: 100%;
    }
  }
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  z-index: 3;
  background: url('/assets/images/forest.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  padding: 32px;
  // animation-name: load;
  // animation-duration: 2s;
  // @keyframes load {
  //   0% {
  //     opacity: 0;
  //   }
  //   25% {
  //     opacity: 0.2;
  //   }
  //   50% {
  //     opacity: 0.5;
  //   }
  //   100% {
  //     opacity: 1;
  //   }
  // }
`;
const Percentage = styled.div`
  color: #fff;
  font-size: 4rem;
  font-family: 'Empires', sans-serif;
`;

export const LoadingProvider = (props: LoadingType) => {
  const location = useLocation();
  const load = useGameStore((state) => state.load);

  return (
    <>
      {location.pathname == '/' ? (
        <>{props.children}</>
      ) : load === 100 ? (
        <>{props.children}</>
      ) : (
        <>
          <LazyWrap className={`${props.className == undefined ? '' : props.className}`}>{props.children}</LazyWrap>
          {load !== 100 && (
            <div className="loaderWrapper">
              <Sky />
              <Clouds />
              <Logo />
            </div>
          )}
          <Loading className={`${props.className == undefined ? '' : props.className}`}>
            <Percentage>{load}%</Percentage>
            <Progressbar
              blurred
              winking={false}
              spark
              barcolor="bg-gradient-to-r from-[#FFF173] to-[#99FFAA]"
              ht="min-h-2"
              percent={`w-[${load}%]`}
              value={null}
              maxvalue={null}
              customStyle={{ width: `${load}%` }}
            />
          </Loading>
        </>
      )}
    </>
  );
};
