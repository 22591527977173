import { Header, Overlay } from "../../components/FightWidgets";

export const Fight = () => {
  return (
    <>
      <Header />
      <Overlay />
    </>
  );
};
