export const BattleIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_361_2504)">
      <path d="M6.31971 0.478516L12.7456 6.90437L7.36946 12.2805L0.943604 5.85462V0.478516H6.31971Z"
            fill="white"/>
      <path
        d="M12.963 22.5078L15.651 25.1959L13.1167 27.7302L9.00776 23.6212L6.3156 26.3134C6.31833 26.3628 6.31971 26.4128 6.31971 26.463C6.31971 27.9475 5.11623 29.1511 3.63165 29.1511C2.14709 29.1511 0.943604 27.9475 0.943604 26.463C0.943604 24.9785 2.14709 23.775 3.63165 23.775C3.68187 23.775 3.73176 23.7764 3.78129 23.7791L6.47344 21.0869L2.36449 16.978L4.89882 14.4436L7.58687 17.1317L24.24 0.478516H29.6161V5.85462L12.963 22.5078Z"
        fill="white"/>
      <path
        d="M26.9282 29.1488C25.4437 29.1488 24.2402 27.9453 24.2402 26.4608C24.2402 26.4106 24.2416 26.3606 24.2443 26.3111L19.0178 21.0847L25.6611 14.4414L28.1954 16.9757L24.0864 21.0847L26.7786 23.7768C26.828 23.7742 26.878 23.7727 26.9282 23.7727C28.4127 23.7727 29.6163 24.9763 29.6163 26.4608C29.6163 27.9453 28.4127 29.1488 26.9282 29.1488Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_361_2504">
        <rect width="28.6725" height="28.6725" fill="white" transform="translate(0.943604 0.478516)"/>
      </clipPath>
    </defs>
  </svg>
);