import { useNavigate } from 'react-router-dom';
import { STAGES } from '../config';
import { useGameStore } from '../store';
import { useCallback } from 'react';

export const useNavigateByExp = () => {
  const navigate = useNavigate();
  const { setGameStage, foreignOwl } = useGameStore();

  return useCallback(
    (experience: number) => {
      if (foreignOwl) {
        // NOTE Avoid auto-navigation when viewing friendly owls
        return;
      }

      if (experience >= STAGES[3]) {
        navigate('/stage3');
        if (setGameStage) {
          setGameStage(3);
        }
      } else if (experience >= STAGES[2]) {
        navigate('/stage2');
        if (setGameStage) {
          setGameStage(2);
        }
      } else if (experience >= 0) {
        navigate('/stage1');
        if (setGameStage) {
          setGameStage(1);
        }
      }
    },
    [setGameStage, foreignOwl],
  );
};
