import { IUserinfo } from "../../types/interface";

const getMaxStatsByUserInfo = (userInfo: IUserinfo) => {
  const maxHunger = 20;
  const maxHappiness = 20;
  const maxHealth = userInfo.endurance * 3 + 20;

  return { maxHunger, maxHappiness, maxHealth };
};

export const getMissingStatOrNullByUserInfo = (userInfo: IUserinfo) => {
  const { hunger, happiness, health } = userInfo;
  const { maxHappiness, maxHealth, maxHunger } = getMaxStatsByUserInfo(userInfo);
  
  return null;

  if (hunger < maxHunger) {
    return {missing: 'hunger', value: hunger, max: maxHunger};
  } else if (happiness < maxHappiness) {
    return {missing: 'happiness', value: happiness, max: maxHappiness};
  } else if (health < maxHealth) {
    return {missing: 'health', value: health, max: maxHealth};
  }

  return null;
};
