import { useMemo } from "react";
import { useGameStore, useUserStore } from "../../store";
import { Drawer } from "vaul";
import { useNavigate } from "react-router";
import { getMissingStatOrNullByUserInfo } from "./shared";

export const MissingStats = ({
  onCloseDrawer
}: {
  onCloseDrawer: () => void;
}) => {
  const navigate = useNavigate();
  const userInfo = useUserStore((state) => state.user);
  const { setGameMode } = useGameStore();
  const missingStat = getMissingStatOrNullByUserInfo(userInfo);

  const [ buttonText, onButtonClick ] = useMemo(() => {
    if (!missingStat) {
      return [null, null];
    }

    if (missingStat.missing === 'hunger') {
      return ['Feed your owl', () => navigate('/store')];
    }

    if (missingStat.missing === 'happiness') {
      return ['Pet your owl', () => setGameMode('normal')];
    }

    if (missingStat.missing === 'health') {
      return ['Heal your owl', () => navigate('/store')];
    }

    return [null, null];
  }, [missingStat]);

  if (!missingStat) {
    return null;
  }

  return (
    <>
      <Drawer.Title className="mb-10 font-bold text-xl text-center max-w-60 mx-auto text-white">
        Fulfill your stats to be prepared for battles
      </Drawer.Title>
      <div className="flex flex-col justify-between relative">
        <div
          className={`flex w-full items-center justify-between my-10 m-auto py-4`}
        >
          <div className="w-3/12 flex items-center">
            <div className="flex flex-col  ml-3">
              <div className="text-white text-sm flex items-center"><span
                className="text-lg mr-1">🐛</span> Hunger
              </div>
              <div className="text-white ml-6">
                {userInfo.hunger} <span className="text-white/[60%]">/ 20</span>
              </div>
            </div>
          </div>
          <div className="w-3/12 flex items-center">
            <div className="flex flex-col">
              <div className="text-white text-sm flex items-center"><span
                className="text-lg mr-1">🥺</span> Happiness
              </div>
              <div className="text-white ml-6">
                {userInfo.happiness} <span className="text-white/[60%]">/ 20</span>
              </div>
            </div>
          </div>
          <div className="w-3/12 flex items-center">
            <div className="flex flex-col">
              <div className="text-white text-sm flex items-center"><span
                className="text-lg mr-1">❤️‍🩹</span> Health
              </div>
              <div className="text-white ml-6">
                {userInfo.health} <span className="text-white/[60%]">/ {userInfo.endurance * 3 + 20}</span>
              </div>
            </div>
          </div>
        </div>
        {buttonText && (
          <div
            onClick={() => {
              onButtonClick();
              onCloseDrawer();
            }}
            className="pointer-events-auto w-full text-white bg-white/[.05] font-medium rounded-lg text-sm px-5 py-4 mb-14 text-center"
          >
            {buttonText}
          </div>
        )}
      </div>
    </>
  );
}