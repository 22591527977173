import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../config';
import IconLeft from '../../assets/icon-left.svg';
import { InventoryInfo } from '../../types/interface';
import { useNavigateByExp } from '../../hooks';
import { useUserStore } from '../../store';
import { MainButton } from '../../components';
import WebApp from "@twa-dev/sdk";
import {useGetInventory} from "../../api";
import {StoreItemType} from "../Store/StoreItemType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const InventoryItemDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getInventory } = useGetInventory();
  const actionByType: { [key: string]: string } = {
    food: 'Use',
    medicine: 'Use',
    egg: 'Open',
    decoration: 'Use',
    decoration_equipped: 'Remove',
    skin: 'Equip',
    skin_equipped: 'Remove',
    jetton: 'Use',
  };
  const userInfo = useUserStore((state) => state.user);
  const setUserInfo = useUserStore((state) => state.setUser);

  const navigateByExp = useNavigateByExp();
  const [item, setItem] = useState<InventoryInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const init = () => {
    const id = Number.parseInt(params.id!);
    const found = userInfo.inventories?.find((item: InventoryInfo) => item.good.id === id);
    if (found === undefined || found === null) return;

    setItem(found);
  };
  const btnText = item && actionByType[`${item.good.type}${item.isEquipped ? '_equipped' : ''}`];
  const handleUse = async (itemType: any, isEquipped: any) => {
    setIsLoading(true);
    try {
      if (item === null) return;
      if (itemType === 'egg') {
        const res = await axios({
          method: 'post',
          url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item.good.id}`,
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        return;
      }
      if (itemType === 'food' || itemType === 'medicine') {
        const { data } = await axios({
          method: 'post',
          url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item.good.id}`,
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUserInfo({
          ...userInfo,
          hunger: data.hunger,
        });
        navigate('/profile');
        setIsLoading(false);
        const inventories = await getInventory();

        if (inventories) {
          setUserInfo({ ...userInfo, inventories });
        }
        return;
      }
      // revert to old item
      let oldItem: InventoryInfo | null = null;
      const old = userInfo.inventories?.find(
        (one: InventoryInfo) => one.isEquipped && one.good.id !== item.good.id && one.good.type === item.good.type,
      );
      // equip new item
      const { data } = await axios({
        method: 'post',
        url: `${REACT_APP_BACKEND_URL}/inventory/${item.good.id}`,
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      const newItem: InventoryInfo = {
        good: data.good,
        amount: data.amount,
        isEquipped: data.is_equipped,
      };
      if (old) {
        oldItem = {
          good: old.good,
          amount: old.amount,
          isEquipped: old.is_equipped,
        };
        setUserInfo({
          ...userInfo,
          inventories: [
            ...userInfo.inventories!.filter(
              (inventory: InventoryInfo) => inventory.good.id !== item.good.id && inventory.good.id !== old?.good.id,
            ),
            newItem,
            oldItem,
          ],
        });
      } else {
        setUserInfo({
          ...userInfo,
          inventories: [
            ...userInfo.inventories!.filter((inventory: InventoryInfo) => inventory.good.id !== item.good.id),
            newItem,
          ],
        });
      }

      setItem(newItem);
      setIsLoading(false);
      navigateByExp(userInfo.totalExp);
    } catch (error) {
      setIsLoading(false);
      console.error('Error during using item:', error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  WebApp.BackButton.onClick(() => {
    navigate('/profile');
  });
  return (
    <Wrapper className="bg-[#080D1B] top-0 absolute">
      <div className="w-full">
        <div className="flex flex-col justify-center items-center gap-6 px-2">
          <div className="w-full px-2 relative">
            {item ? (
              <>
                {item.good.type !== "egg" && (
                  <div className="absolute left-0 top-3 right-[-204px] w-fit m-auto">
                    <StoreItemType type={item.good.type} />
                  </div>
                )}
                <img className="aspect-square w-full h-full rounded-[36px]" src={item.good.img} alt="item" />
                {item.good.type === "skin" &&
                  <>
                  {item.isEquipped ? (
                    <span className="flex items-center text-sm font-medium absolute top-5 left-7">
                      <img src="https://notwise.b-cdn.net/icons/equi.svg" className="w-5 min-w-5" alt="icon" />
                      <span className="flex items-center font-medium absolute left-4 px-2 text-xs text-white/[60%]">
                        Equipped
                      </span>
                    </span>
                  ) : (
                    <span className="flex items-center text-sm font-medium absolute top-5 left-7">
                      <img src="https://notwise.b-cdn.net/icons/unequi.svg" className="w-5 min-w-5" alt="icon" />
                       <span className="flex items-center font-medium absolute left-4 px-2 text-xs text-white/[60%] w-max">
                        NOT Equipped
                      </span>
                    </span>
                  )}
                    </>
                }
              </>
            ) : (
              <div className="aspect-square w-full h-full rounded-[36px]" />
            )}
          </div>
          <div className="w-full text-center">
            <h4 className="text-2xl font-bold text-white mb-1">{item ? item.good.name : 'Loading...'}</h4>
            <div className="text-sm font-normal text-white">{item ? item.good.description : 'Loading...'}</div>
            <div className="w-full mt-2">
              {item &&
                item.good.traits &&
                Object.entries(item.good.traits).map(([key, value]) => (
                  <div className="flex flex-col items-center text-white/[60%] text-sm" key={key}>
                    <div className="flex">
                      {key.charAt(0).toUpperCase() + key.slice(1)}:&nbsp;
                      <span className="font-medium text-white">{value as string}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <MainButton
          onClick={() => handleUse(item?.good.type, item?.isEquipped)}
          isLoading={isLoading}
          btnText={btnText}
        />
      </div>
    </Wrapper>
  );
};
