import React, { useMemo } from 'react';
import { LottiePlayer } from '../../components';

type Achievement = {
  id: number;
  name: string;
  img: string;
  short_descr: string;
};

type AchievementsGridProps = {
  achievements: Achievement[];
  userAchievements: Array<unknown>;
};

const AchievementsGrid: React.FC<AchievementsGridProps> = React.memo(({ achievements, userAchievements }) => {
  const renderedAchievements = useMemo(() => {
    return achievements.map(({ id, name, img, short_descr }) => {
      const isBlocked = !userAchievements.includes(id);

      return (
        <div key={id} className="achievement-item">
          <LottiePlayer
            lottieUrl={img}
            isLocked={isBlocked}
            isPlaying={!isBlocked}
            lockedReason={short_descr}
          />
        </div>
      );
    });
  }, [achievements, userAchievements]);

  return <div className="grid grid-cols-3 gap-2 mt-2">{renderedAchievements}</div>;
});

export default AchievementsGrid
