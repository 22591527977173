import styled from 'styled-components';
import React, { Suspense, useState } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useWalletStore } from '../../store';

const StoriesLazy = React.lazy(() => import('react-insta-stories'));
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
`;

function Story1({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#055bc6] to-[#070300]">
      <h1 className="mx-3 mt-10 text-3xl uppercase font-bold text-white text-center">Grow & pet your pet</h1>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/1.png" />
      </div>
    </div>
  );
}
function Story2({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#2e0707] to-[#070300]">
      <h1 className="mx-3 mt-10 text-3xl uppercase font-bold text-white text-center">Fight or hide</h1>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/2.png" />
      </div>
    </div>
  );
}
function Story3({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#ffbc1a] to-[#070300]">
      <h1 className="mx-3 mt-10 text-3xl uppercase font-bold text-white text-center">Participate in contests</h1>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/3.png" />
      </div>
    </div>
  );
}
function Story4({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#265f14] to-[#070300]">
      <h1 className="mx-3 mt-10 text-3xl uppercase font-bold text-white text-center">Invite Frens</h1>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/4.png" />
      </div>
    </div>
  );
}
function Story5({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#f99f1e] to-[#070300]">
      <h1 className="mx-3 mt-10 text-3xl uppercase font-bold text-white text-center">Earn Notcoin</h1>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/5.png" />
      </div>
    </div>
  );
}

const stories2 = [
  {
    content: Story1,
  },
  {
    content: Story2,
  },
  {
    content: Story3,
  },
  {
    content: Story4,
  },
  {
    content: Story5,
  },
];

export const Stories = () => {
  const wallet = useWalletStore((state) => state.wallet);
  const [tonConnectUI] = useTonConnectUI();
  const [notInstallTelegram] = useState<boolean>(false);

  const handleWallet = async () => {
    await (wallet.connected ? tonConnectUI.disconnect() : tonConnectUI.openModal());
  };

  return (
    <Wrapper>
      <Suspense>
        <StoriesLazy
          preloadCount={3}
          loop
          keyboardNavigation
          defaultInterval={8000}
          stories={stories2}
          width="100%"
          height="100%"
          onStoryEnd={(s: any, st: any) => console.log('story ended', s, st)}
          onAllStoriesEnd={(s: any, st: any) => console.log('all stories ended', s, st)}
          onStoryStart={(s: any, st: any) => console.log('story started', s, st)}
          onNext={() => console.log('next button pressed')}
          onPrevious={() => console.log('previous button pressed')}
          storyContainerStyles={{ overflow: 'hidden', pointerEvents: 'auto' }}
        />
      </Suspense>
      {!wallet.hasNFT && wallet.connected && (
        <div className="bg-red-950/[.8] backdrop-blur border border-red-500/[.2] p-3 rounded absolute bottom-52 text-sm text-white font-semibold text-center my-20">
          🚨 Closed βeta
          <br />
          <span className="text-xs font-normal">Access for NOT Wise & NOT Lottoque NFT holders only</span>
        </div>
      )}
      {notInstallTelegram && (
        <div className="absolute bottom-10 text-sm text-white font-semibold text-center my-20">
          Oops! It's better to play via Telegram.
          <br />
          Please run game using bot.
        </div>
      )}
      <div
        className="flex flex-col justify-center items-center my-10 w-full absolute bottom-0"
        style={{ zIndex: 1000 }}
      >
        <div className="flex flex-col items-center text-center">
          <h1 className="text-xl w-10/12 backdrop-blur text-white font-bold mb-4 bg-white/[.06] p-1 px-3 rounded border border-white/[.06]">
            βeta test 🚧
            <br />
            <span className="text-white font-normal line-clamp-2 text-xs">
              World may be changed or improved. All the progress will be reset on release.
            </span>
          </h1>
        </div>
        <button
          className="text-sm text-white font-medium w-10/12 rounded-md bg-[#0094FF] px-8 py-3 pointer-events-auto"
          onClick={handleWallet}
        >
          {wallet.connected ? (
            <>
              Disconnect wallet: {wallet.address.slice(0, 6)}...
              {wallet.address.slice(-6)}
            </>
          ) : (
            'Connect Wallet'
          )}
        </button>
      </div>
    </Wrapper>
  );
};
