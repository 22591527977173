import { useCallback, useEffect, useRef, useState } from "react";
import { useGameStore, useUserStore } from "../../../../store";
import { detectSwipeModelIntersection, handleAnimation } from "../../../../utils";
import { ParticleSystemUtil } from "../../particles/ParticleSystemUtil";
import { Coords } from "../../components";
import { AnimationAction, Object3D } from "three";
import { useThree } from "@react-three/fiber";
import { useUserAction } from "../../../../hooks";
import { BatchedRenderer } from "three.quarks";
import { LEVELS } from "../../../../config";

const TOP_MODEL_INTERSECTION_OFFSET = -1;
const BOTTOM_MODEL_INTERSECTION_OFFSET = -0.2;
const LEFT_MODEL_INTERSECTION_OFFSET = -0.1;
const RIGHT_MODEL_INTERSECTION_OFFSET = 0.1;

export const useIdleOwlControls = (enabled: boolean = false, {
  owl,
  actions,
  batchedRenderer,
  particleEffects
}: {
  owl: Object3D | null;
  actions: Record<string, AnimationAction | null> | null;
  batchedRenderer: BatchedRenderer;
  particleEffects: Record<string, Object3D | undefined>;
}) => {
  const [intersectionStart, setIntersectionStart] = useState(false);
  const [intersectionEnd, setIntersectionEnd] = useState(false);
  const { scene: mainScene, camera } = useThree();
  const { handleSwipeAction } = useUserAction('owl');
  const { user: userInfo, addExperienceAndReduceEnergy, setLevel } = useUserStore();
  const { currentPointerCoords, setCurrentPointerCoords } = useGameStore((state) => ({
    currentPointerCoords: state.currentPointerCoords,
    setCurrentPointerCoords: state.setCurrentPointerCoords,
  }));
  const startIntersectionPoint = useRef<Coords>({ x: 0, y: 0 });

  const onClick = (event: MouseEvent) => {
    if (!actions || !owl) {
      return;
    }

    addExperienceAndReduceEnergy(100.0, 0.0);

    event.stopPropagation();

    if (actions['OwlGroup|Take 001|BaseLayer']) {
      handleAnimation({
        target: owl,
        model: 'owl',
        action: actions['OwlGroup|Take 001|BaseLayer'],
        animationName: 'look_with_interest_v2',
        isLoop: false,
        onComplete: () => {
          handleAnimation({
            target: owl,
            model: 'owl',
            action: actions['OwlGroup|Take 001|BaseLayer'],
            animationName: 'idle',
            isLoop: true,
          });
        },
      });
    }
    ParticleSystemUtil.playEffect('QuestionsMark', mainScene, particleEffects.questionsMark, batchedRenderer);
  };

  const onSwipe = useCallback(
    (point: Coords, model: Object3D) => {
      if (!actions || !owl) {
        return;
      }

      const isIntersection = detectSwipeModelIntersection({
        model,
        point,
        camera,
        offset: {
          top: TOP_MODEL_INTERSECTION_OFFSET,
          bottom: BOTTOM_MODEL_INTERSECTION_OFFSET,
          left: LEFT_MODEL_INTERSECTION_OFFSET,
          right: RIGHT_MODEL_INTERSECTION_OFFSET,
        },
      });

      if (isIntersection && !intersectionStart) {
        setIntersectionStart(true);
        startIntersectionPoint.current = point;
      }

      if (intersectionStart && !isIntersection && !intersectionEnd) {
        setIntersectionEnd(true);
      }

      if (intersectionEnd) {
        setIntersectionStart(false);
        setIntersectionEnd(false);
        setCurrentPointerCoords(null);

        handleSwipeAction(mainScene, particleEffects.heartPoof, batchedRenderer);

        handleAnimation({
          target: owl,
          model: 'owl',
          action: actions['OwlGroup|Take 001|BaseLayer'],
          animationName: 'satisfied_v1',
          isLoop: false,
          onComplete: () => {
            handleAnimation({
              target: owl,
              model: 'owl',
              action: actions['OwlGroup|Take 001|BaseLayer'],
              animationName: 'idle',
              isLoop: true,
            });
          },
        });
      }
    },
    [
      actions,
      camera,
      handleSwipeAction,
      intersectionEnd,
      intersectionStart,
      mainScene,
      setCurrentPointerCoords,
      particleEffects.heartPoof
    ],
  );

  useEffect(() => {
    if (!enabled || !owl || !currentPointerCoords) {
      return;
    }

    onSwipe(currentPointerCoords, owl);
  }, [enabled, owl, currentPointerCoords, onSwipe]);

  useEffect(() => {
    if (!enabled || !actions) {
      return;
    }

    handleAnimation({
      target: owl,
      model: 'owl',
      action: actions['OwlGroup|Take 001|BaseLayer'],
      animationName: 'idle',
      isLoop: true,
    });
  }, [actions, owl]);

  useEffect(() => {
    if (!particleEffects.levelUp) {
      return;
    }

    let updatedLevel = userInfo.level;
    let deltaLevels = 0;

    if (userInfo.totalExp >= LEVELS[updatedLevel + 1]) {
      while (userInfo.totalExp >= LEVELS[updatedLevel + 1]) {
        updatedLevel += 1;
        deltaLevels += 1;
      }
    }

    setLevel(updatedLevel);

    for (let i = 0; i < deltaLevels; i++) {
      setTimeout(() => {
        ParticleSystemUtil.playEffect('LevelUp', mainScene, particleEffects.levelUp, batchedRenderer);
      }, i * 300);
    }
  }, [userInfo.totalExp, LEVELS, particleEffects?.levelUp]);

  return enabled ? {
    onClick
  } : {};
};
