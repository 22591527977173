import { Coords } from '../hooks';

export function showExperienceAnimation(coords: Coords, amount: number) {
  const x = coords.x;
  const y = coords.y;

  const addExpData = {
    x,
    y,
    amount,
  };
  const addExp = new CustomEvent('addExp', { detail: addExpData });

  document.dispatchEvent(addExp);
}
