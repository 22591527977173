import React, { useEffect } from 'react';
import { useWebSocketContext } from './';
import {useUserStore} from "../../store";
import toast from "react-hot-toast";

type WebSocketMessage = {
  type: string;
  id: number;
  name: string;
  desc: string;
};

const AchievementListener: React.FC = () => {
  const achievements = useUserStore((state) => state.user.achievements);
  const setAchievements = useUserStore((state) => state.setAchievements);
  const { lastMessage } = useWebSocketContext();

  useEffect(() => {
    if (lastMessage) {
      try {
        const data: WebSocketMessage = JSON.parse(lastMessage.data);

        if (data.type && data.type === 'UNLOCK') {
          handleUnlockEvent(data);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [lastMessage]);

  const handleUnlockEvent = (data: WebSocketMessage) => {
    const isAlreadyUnlocked = achievements.includes(data.id);
    if (!isAlreadyUnlocked) {
      setAchievements([...achievements, data.id]);
      toast((t) => (
        <span>
          <span className="text-xs text-white/[.6]">Achievement unlocked</span><br/>
          <b>{data.name}</b><br/>
          <span className={"text-xs"}>{data.desc}</span>
        </span>
      ), {icon: '🏆', style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
          padding: '1rem 2rem',
        }});
    }
  };

  return null;
};

export default AchievementListener;
