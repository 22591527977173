import { useEffect, useState } from 'react';
import { Camera, Object3D, Object3DEventMap, Raycaster, Texture, Vector3 } from 'three';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem, QuarksLoader, QuarksUtil } from 'three.quarks';
import { convertUV } from '../../../utils/three';

export const ParticleSystemUtil = {
  getIntersections(
    x: number,
    y: number,
    ref: Object3D<Object3DEventMap>,
    camera: Camera
  ) {
    const raycaster = new Raycaster();
    raycaster.setFromCamera(convertUV(x, y), camera);
    return raycaster.intersectObjects([ref], true);
  },

  playEffect(
    name: string,
    scene: Object3D<Object3DEventMap>,
    effect: Object3D<Object3DEventMap> | undefined,
    batchedRenderer: BatchedRenderer,
    rotate?: number,
    texture?: Texture
  ) {
    if (!effect) {
      console.error(`Effect ${name} not found`);
      return;
    }

    const clonedEffect = effect.clone(true);

    if (rotate && name === 'ClawAttack') {
      const emitter = clonedEffect.children[0] as ParticleEmitter;
      (emitter.system as ParticleSystem).startRotation = new ConstantValue(rotate);
    }

    if (texture) {
      const emitter = clonedEffect.children[0] as ParticleEmitter;
      (emitter.system as ParticleSystem).texture = texture;
    }
    scene.add(clonedEffect);
    QuarksUtil.addToBatchRenderer(clonedEffect, batchedRenderer);
    QuarksUtil.restart(clonedEffect);
    return clonedEffect;
  },

  updateHeatEffect(
    temperature: number,
    effect: Object3D<Object3DEventMap> | undefined
  ) {
    if (!effect || temperature === null) {
        console.error('EggHeat effect or temperature not found');
        return;
    }

    effect.traverse((obj: Object3D) => {
      if (obj instanceof ParticleEmitter) {
        const system = obj.system as ParticleSystem;
        console.log("temperature", temperature);
        system.emissionOverTime = new ConstantValue(
            temperature > 10 ? 30 : 0
        );
      }
    });
  },

  prepareEffect(
    name: string,
    effect: Object3D<Object3DEventMap> | undefined,
    position?: Vector3,
    scale?: number
  ) {
    if (!effect) {
      console.error(`Effect ${name} not found`);
      return;
    }

    QuarksUtil.setAutoDestroy(effect, true);
    QuarksUtil.stop(effect);
    if (position) {
      effect.position.copy(position);
    }
    if (scale) {
      effect.scale.set(scale, scale, scale);
    }
  },

  useQuarks : (url: string) => {
    const [object, setObject] = useState<Object3D>();
    useEffect(() => {
      const loader = new QuarksLoader();
      loader.load(url, function (object: Object3D) {
        setObject(object);
      });
    }, [url]);
    return object;
  },
};
