import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IconLeft from '../../assets/icon-left.svg';
import Branch from '../../assets/icon-branch.svg';
import TonIcon from '../../assets/icon-ton.svg';
import { SQUAD_ARRAY } from '../../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

const setColor = (rate: number) => {
  if (rate >= 50) {
    return 'text-[#FFC700]';
  }
  if (rate >= 25) {
    return 'text-[#DADADA]';
  }
  if (rate >= 12.5) {
    return 'text-[#FFAC88]';
  }
  if (rate >= 6.25) {
    return 'text-[#FFFFFF60]';
  }
  return 'text-[#FFFFFF60]';
};

export const LeaderBoard = () => {
  return (
    <Wrapper className="bg-gradient-to-t from-[#5984AB] to-[#242424]">
      <div className="w-full">
        <div className="flex items-center gap-2 mb-1">
          <button className="w-4 h-full">
            <img src={IconLeft} alt="IconLeft" />
          </button>
          <h6 className="text-2xl font-bold text-white">
            <span className="text-[#FFB800]">EXP</span> Leaderboard
          </h6>
        </div>
        <div className="flex flex-col justify-center items-center mt-6 mb-8 gap-1">
          <div className="gap-2">
            <p className="text-xs font-normal text-white text-center opacity-40">estimated prize pool</p>
            <div className="flex justify-center items-center gap-2 text-2xl font-bold text-white">
              <img src={TonIcon} alt="TonIcon" /> 22231
            </div>
          </div>
          <div className="gap-2">
            <p className="text-xs font-normal text-white text-center opacity-40">next payout date</p>
            <div className="flex justify-center items-center gap-2 text-2xl font-bold text-white">21-05-2024</div>
          </div>
        </div>

        <div className="max-h-64 overflow-y-auto scroll-mr-6">
          {SQUAD_ARRAY.map((item, index) => (
            <div className="grid grid-cols-12 items-center gap-2 mb-2" key={index}>
              <div className="col-span-1 text-[10px] font-bold text-white">
                <Link to={`/squad/${index + 1}`}>#{index + 1}</Link>
              </div>
              <div className="col-span-5">
                <div className="flex items-center gap-2">
                  <div className="flex justify-center items-center w-3 h-3 rounded-full bg-[#D9D9D9]" />
                  <h6 className="text-xs font-normal text-white opacity-60">{item.exp} EXP</h6>
                </div>
              </div>
              <div className="col-span-3">
                <div className="flex items-center gap-1">
                  <span className={`text-xs font-bold ${setColor(item.rate)}`}>{item.rate}%</span>
                </div>
              </div>
              <div className="col-span-3">
                <button className="flex justify-center items-center gap-1 w-full text-[8px] text-white font-bold rounded-md bg-[#D9D9D950] py-1">
                  <img src={Branch} alt="branch" />
                  branch
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
