export const getExperienceBasedOnTemperature = (temperature: number) => {
  if (temperature < 4 || temperature > 16) {
    return 1;
  }

  if (temperature === 10) {
    return 3;
  }

  if (temperature >= 7 && temperature <= 13) {
    return 2;
  }

  return 1;
};
