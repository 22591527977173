import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

export const LottiePlayer = ({
  lottieUrl,
  isLocked,
  lockedReason,
  isPlaying = true,
}: {
  lottieUrl: string | null;
  isLocked?: boolean;
  lockedReason?: string;
  isPlaying?: boolean;
}) => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    if (lottieUrl) {
      fetch(lottieUrl)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error('Error loading Lottie animation:', error));
    }
  }, [lottieUrl]);

  if (!animationData) {
    return <div className="rounded-xl w-full aspect-[1/1] bg-slate-700 animate-pulse" />; // Show a loading state while the animation is being fetched
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="relative rounded-xl overflow-hidden">
      <Lottie isClickToPauseDisabled options={defaultOptions}  isStopped={!isPlaying}
        isPaused={!isPlaying} />
      {!animationData && <div className="w-full aspect-[1/1] bg-slate-700 animate-pulse" />}

      {isLocked && animationData && (

        <div
          className="absolute top-0 w-full h-full flex flex-col justify-center items-center bg-[#0F152A]/[.8] backdrop-blur p-1"
        >
          <span className="nw-icon-locked text-3xl text-white"></span>
          <div className="absolute bottom-3 text-[10px] font-bold">{lockedReason}</div>
        </div>
      )}
    </div>
  );
};

