import { useAnimations } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { getItemPosition } from '../../../utils';
import { useGameStore, useUserStore } from '../../../store';
import { useOwlSkins } from '../../../hooks/useOwlSkins';
import { getModelHeight } from '../../../utils/getModelHeight';
import { ParticleSystemUtil } from '../particles/ParticleSystemUtil';
import { BatchedRenderer } from 'three.quarks';
import { MODEL_URLS } from '../../../constants';
import { useGLTF } from '../hooks/useGLTF';
import { useIdleOwlControls } from '../hooks/owlControls/useIdleOwlControls';
import { useBattleOwlControls } from '../hooks/owlControls/useBattleOwlControls';
import { Object3D, Vector3 } from 'three';
import { getLayerByRenderOrder } from './composition/Layer';
import { useBindObject3DToLayer } from '../hooks/useBindObject3DToLayer';
import { useOwlNeedsControls } from '../hooks/owlControls/useOwlNeedsControls';

export const OwlModel = ({
  mode = 'default',
  isForeignOwl
}: {
  mode: 'default' | 'battle';
  isForeignOwl?: boolean
}) => {
  const { camera, size } = useThree();
  const {
    foreignOwl,
    animating,
    setNeedsPosition,
  } = useGameStore((state) => ({
    foreignOwl: (isForeignOwl ? state.foreignOwl : null),
    animating: state.animating,
    setNeedsPosition: state.setNeedsPosition,
  }));
  const { scene, animations } = useGLTF(MODEL_URLS.owl, isForeignOwl ? 'foreignOwl' : 'owl');
  const { actions } = useAnimations(animations, scene);
  const quarksEffect = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise2_effect);
  // NOTE Owl skins
  const inventory = useUserStore((state) => state.user.inventories);
  const equippedItems = inventory?.filter((item: any) => item.isEquipped);
  const equippedSkins = equippedItems?.filter((item: any) => item.good.type === 'skin');
  useOwlSkins(scene, (isForeignOwl ? foreignOwl?.items : equippedSkins) || []);

  const owlRef = useRef<Object3D | null>(null);
  const heartPoof = useRef<Object3D>();
  const questionsMark = useRef<Object3D>();
  const healOnce = useRef<Object3D>();
  const feed = useRef<Object3D>();
  const bang = useRef<Object3D>();
  const clawAttack = useRef<Object3D>();
  const boom = useRef<Object3D>();
  const levelUp = useRef<Object3D>();
  const batchedRenderer = useRef(new BatchedRenderer());

  const controlsPayload = {
    owl: owlRef.current,
    actions,
    batchedRenderer: batchedRenderer.current,
    isForeignOwl,
    particleEffects: {
      heartPoof: heartPoof.current,
      questionsMark: questionsMark.current,
      healOnce: healOnce.current,
      feed: feed.current,
      bang: bang.current,
      clawAttack: clawAttack.current,
      boom: boom.current,
      levelUp: levelUp.current,
    },
  };
  const idleControls = useIdleOwlControls(mode === 'default', controlsPayload);
  const battleControls = useBattleOwlControls(mode === 'battle', controlsPayload);
  const needsControls = useOwlNeedsControls(mode === 'default' && !isForeignOwl, controlsPayload);

  useEffect(() => {
    if (!owlRef.current || animating) {
      return;
    }

    // NOTE This should be a relative position
    const owlPosition = new Vector3().setScalar(0.0);
    owlRef.current.getWorldPosition(owlPosition);

    const owlHeight = getModelHeight(owlRef.current);

    const offsetX = 1;
    const offsetY = owlHeight - 1;

    const needsPosition = getItemPosition({ anchorVector: owlPosition, camera, offsetX, offsetY, size });
    setNeedsPosition(needsPosition);
  }, [animating, camera, setNeedsPosition, size]);

  useBindObject3DToLayer(batchedRenderer.current, getLayerByRenderOrder(mode === 'battle' ? 1 : 0));

  useEffect(() => {
    if (!quarksEffect) {
      return;
    }

    heartPoof.current = quarksEffect.getObjectByName('HeartPoof')!;
    questionsMark.current = quarksEffect.getObjectByName('QuestionsMark')!;
    healOnce.current = quarksEffect.getObjectByName('HealOnce')!;
    feed.current = quarksEffect.getObjectByName('Feed')!;
    bang.current = quarksEffect.getObjectByName('Bang')!;
    clawAttack.current = quarksEffect.getObjectByName('ClawAttack')!;
    boom.current = quarksEffect.getObjectByName('Boom')!;
    levelUp.current = quarksEffect.getObjectByName('LevelUp')!;

    ParticleSystemUtil.prepareEffect('HeartPoof', heartPoof.current, new Vector3(0, 7, 3));
    ParticleSystemUtil.prepareEffect('QuestionsMark', questionsMark.current, new Vector3(0, 7, 3));
    ParticleSystemUtil.prepareEffect('HealOnce', healOnce.current, new Vector3(0, 5, 10));
    ParticleSystemUtil.prepareEffect('Feed', feed.current, new Vector3(0, 8.5, 5));
    ParticleSystemUtil.prepareEffect('Bang', bang.current);
    ParticleSystemUtil.prepareEffect('ClawAttack', clawAttack.current);
    ParticleSystemUtil.prepareEffect('Boom', boom.current);
    ParticleSystemUtil.prepareEffect('LevelUp', levelUp.current);
  }, [quarksEffect]);

  useFrame((_, delta) => {
    batchedRenderer.current.update(delta);
  });

  return (
    <primitive
      ref={owlRef}
      object={scene}
      {...idleControls}
      {...needsControls}
      {...battleControls}
    />
  );
};
