import { useMutation } from './useMutation';

export const useRefillEnergy = () => {
  const { mutate, ...rest } = useMutation<string>({
    url: 'refill_energy/',
    method: 'get',
  });

  return { refillEnergy: mutate, ...rest };
};
