import React from 'react';
import {useGameStore} from "../../store";
import {LottiePlayer} from "../../components";

export const Home = () => {
  const isBlocked = useGameStore((state)=> state.isBlocked );
  return (
    <>
    {
      !isBlocked ?
        <div className="flex h-dvh w-full bg-black text-white items-center justify-center absolute z-10">
          <div className={"flex flex-col items-center justify-center w-10/12"}>
            <div className={"text-center"}>
              <h2 className={"text-xs font-bold uppercase mt-5 mb-2"}>Initializing...</h2>
            </div>
          </div>
        </div>
        :
        <div className="flex h-dvh w-full bg-black text-white items-center justify-center absolute z-10">
          <div className={"flex flex-col items-center justify-center w-10/12"}>
            <div className={"w-32"}>
              <LottiePlayer lottieUrl={'https://cdn.notwise.co/stickers/24.json'} isPlaying={true}/>
            </div>
            <div className={"text-center"}>
              <h2 className={"text-2xl font-bold uppercase mt-5 mb-2"}>A bit late, browl</h2>
              NOT Wise open beta is now limited to 1000 participants.
            </div>
          </div>
        </div>
    }
    </>
  );
};
