import { Dispatch, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useGameStore } from '../../../store';
import { useThree } from '@react-three/fiber';
import { getViewByGameMode, SceneView, Views } from '../views';

export const useCameraAnimation = ({
  setSceneView,
}: {
  setSceneView: Dispatch<SceneView>;
}) => {
  const { camera } = useThree();
  const initialRender = useRef(true);
  const [
    gameMode,
    setAnimating,
    setNeedsVisibility,
  ] = useGameStore((state) => [
    state.gameMode,
    state.setAnimating,
    state.setNeedsVisibility,
  ]);
  const verticalTween = (startY: number, endY: number, targetSceneView: SceneView) => {
    gsap.to(camera.position, {
      x: 0,
      y: endY,
      z: 20,
      duration: 2,
      ease: 'back.inOut(.7)',
      onStart: () => {
        setAnimating(true);
  
        setNeedsVisibility(false);
      },
      onUpdate: () => {
        if (startY === endY) {
          return;
        }

        const progress = 1.0 - Math.abs((camera.position.y - endY) / (startY - endY));
  
        if (progress >= 0.5 && progress < 0.6) {
          setSceneView(targetSceneView);
        }
      },
      onComplete: () => {
        setAnimating(false);
      },
    });
  };

  useEffect(() => {
    if (!camera) {
      return;
    }

    if (initialRender.current) {
      initialRender.current = false;
      gsap.fromTo(
        camera.position,
        {
          x: 0,
          y: 7,
          z: 15
        },
        {
          x: 0,
          y: 7,
          z: 20,
          duration: 2,
          ease: 'power2.inOut',
          onComplete: () => setAnimating(false),
        },
      );
    }
  }, [camera]);

  useEffect(() => {
    if (!camera) {
      return;
    }

    const activeView = getViewByGameMode(gameMode);

    verticalTween(camera.position.y, Views[activeView].cameraPosition, activeView);
  }, [gameMode, camera]);

  return null;
};
