type Props = {
  items: Array<{
    title: string;
    isActive: boolean;
  }>;
  onSelect: (idx: number) => void;
};

export const Tabs = ({ items, onSelect }: Props) => {
  return (
    <div className="flex space-x-8">
      {items.map(({ title, isActive }, idx) => (
        <button
          key={idx}
          className="flex flex-col space-y-2"
          onClick={() => {
            console.log(idx);
            onSelect(idx);
          }}
        >
          <div className={`${isActive ? 'text-white' : 'text-[#e3eaf699]'}`}>{title}</div>
          <div className={`h-0.5 rounded-tl-xl rounded-tr-xl bg-white ${isActive ? 'visible' : 'hidden'} w-full`} />
        </button>
      ))}
    </div>
  );
};
