import { CDN_GATEWAY_BASE } from '../config';

type Timeline = Record<string, {
  startFrame: number;
  endFrame: number;
}>;

export const MODEL_URLS = {
  // owl_big: `${CDN_GATEWAY_BASE}/models/Owl_anim_attributes_glb.glb`,
  owl: `${CDN_GATEWAY_BASE}/models/owl-final.glb`,
  dummy: `${CDN_GATEWAY_BASE}/models/dummy_x.glb`,
  tree: `${CDN_GATEWAY_BASE}/models/tree5.glb`,
  egg: `${CDN_GATEWAY_BASE}/models/egg2.glb`,
  notwise1_effect: `${CDN_GATEWAY_BASE}/effects/notwise1.json`,
  notwise2_effect: `${CDN_GATEWAY_BASE}/effects/notwise2.json`,
  textures: `${CDN_GATEWAY_BASE}/Textures`,
};

export const TEXTURE_URLS = {
  food1: `${CDN_GATEWAY_BASE}/images/quarks/food1.png`,
  food2: `${CDN_GATEWAY_BASE}/images/quarks/food2.png`,
  food3: `${CDN_GATEWAY_BASE}/images/quarks/food3.png`,
};

export const OWL_ANIMATION_TIMELINE: Timeline = {
  idle: {
    startFrame: 10,
    endFrame: 1030,
  },
  falls_asleep_v1: {
    startFrame: 1040,
    endFrame: 1275,
  },
  wakes_up_v1: {
    startFrame: 1285,
    endFrame: 1320,
  },
  falls_asleep_v2: {
    startFrame: 1330,
    endFrame: 1445,
  },
  wakes_up_v2: {
    startFrame: 1455,
    endFrame: 1635,
  },
  sleep: {
    startFrame: 1645,
    endFrame: 1925,
  },
  agressive_v1: {
    startFrame: 1935,
    endFrame: 2040,
  },
  attack_v1: {
    startFrame: 2050,
    endFrame: 2075,
  },
  agressive_v2: {
    startFrame: 2085,
    endFrame: 2315,
  },
  attack_v2: {
    startFrame: 2325,
    endFrame: 2370,
  },
  satisfied_v1: {
    startFrame: 2380,
    endFrame: 2470,
  },
  satisfied_v2: {
    startFrame: 2480,
    endFrame: 2565,
  },
  look_with_interest_v1: {
    startFrame: 2575,
    endFrame: 2925,
  },
  look_with_interest_v2: {
    startFrame: 2935,
    endFrame: 3285,
  },
  owl_is_ill_v1: {
    startFrame: 3295,
    endFrame: 3615,
  },
  owl_is_ill_v2: {
    startFrame: 3625,
    endFrame: 3945,
  },
  attack_head: {
    startFrame: 4210,
    endFrame: 4255,
  },
  attack_body: {
    startFrame: 4265,
    endFrame: 4305,
  },
  attack_stomach: {
    startFrame: 4315,
    endFrame: 4350,
  },
  super_attack: {
    startFrame: 4360,
    endFrame: 4450,
  },
  idle_new: {
    startFrame: 4460,
    endFrame: 4665,
  },
  damage_head: {
    startFrame: 4675,
    endFrame: 4720,
  },
  damage_body: {
    startFrame: 4730,
    endFrame: 4770,
  },
  damage_stomach: {
    startFrame: 4780,
    endFrame: 4815,
  },
  super_damage: {
    startFrame: 4825,
    endFrame: 4915,
  },
};

export const OWL_ANIMATION_NAMES = Object.keys(OWL_ANIMATION_TIMELINE);

export const EGG_ANIMATION_TIMELINE: Timeline = {
  T_pose: {
    startFrame: 0,
    endFrame: 0,
  },
  anim_Tap_01: {
    startFrame: 10,
    endFrame: 30,
  },
  anim_Tap_02: {
    startFrame: 40,
    endFrame: 80,
  },
  anim_Tap_03: {
    startFrame: 90,
    endFrame: 120,
  },
  anim_Tap_04: {
    startFrame: 130,
    endFrame: 150,
  },
};

export const EGG_ANIMATION_NAMES = Object.keys(EGG_ANIMATION_TIMELINE);

export const TREE_ANIMATION_TIMELINE: Timeline = {
  idle_tree: {
    startFrame: 0,
    endFrame: 1082,
  },
};

export const TREE_ANIMATION_NAMES = Object.keys(TREE_ANIMATION_TIMELINE);

export const DUMMY_ANIMATION_TIMELINE: Timeline = {
  T_pose: {
    startFrame: 0,
    endFrame: 0,
  },
  damage_v1: {
    startFrame: 10,
    endFrame: 50,
  },
  damage_v2: {
    startFrame: 60,
    endFrame: 115,
  },
  damage_v3: {
    startFrame: 125,
    endFrame: 165,
  },
  damage_v4: {
    startFrame: 175,
    endFrame: 230,
  },
  damage_v5: {
    startFrame: 240,
    endFrame: 290,
  },
};

export const DUMMY_ANIMATION_NAMES = Object.keys(DUMMY_ANIMATION_TIMELINE);

export const SQUAD_ARRAY = [{ exp: 162366, rate: 50 }];

export const LEADERBIRD_ARRAY = [
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
  { username: 'mcseemio', exp: 162366, coins: 50 },
];

export const FRENS_ARRAY = [
  { username: 'mcseemio', earn: 330 },
  { username: 'user1', earn: 130 },
  { username: 'user2', earn: 30 },
];

export const SKILL_DESCR: any = {
  Strength: 'Attack power',
  Agility: 'Evasion chance',
  Wisdom: 'Critical chance',
  Endurance: 'Max HP supply',
};
export const chestData: any = {
  'Bow Tie': '',
  'NOT Chainz': ['Chaiz_NOT_low', 'Chaiz_1_low', 'Chaiz_2_low', 'Chaiz_Ring_1_low'],
  'Bolo Tie': '',
  Tie: '',
  None: '',
};
export const owlBodyElements = [
  'Owl_Eyelips_up_low',
  'Owl_Body_low',
  'Owl_Tail_mid_low',
  'Owl_Body_Wings_low',
];
export const owlHeadElements = ['Owl_Eyebrows_low', 'Owl_Eyelips_up_low', 'Owl_Head_low'];
export const owlEyesElements = ['Owl_Eyes_low'];
export const owlNonChangableElements = [
  'Owl_Tongue_low',
  'Owl_Blink_low',
];
export const owlBeakElements = [
  'Owl_BeakDown_low',
  'Owl_BeakUp_low',
];
export const owlLegsElements = [
  'Owl_Legs_low',
];

const body = [
  'Artistry',
  'Blue',
  'Buckle',
  'Camo',
  'Cherry',
  'Pure Gold',
  'Gray',
  'Green',
  'Brown',
  'Nightcity',
  'Polar',
  'Python',
  'Rose',
  'Violet',
  'Demon',
];

const chest = ['Bow Tie', 'NOT Chainz', 'Bolo Tie', 'Tie', 'None'];

const cigarette = ['Cigarette', 'Pipe', 'None'];

const glasses = ['Aviator', 'Pirate', 'Las Vegas'];

const hat = [
  'Birthday Hat',
  'Broken Egg',
  'Captain Hat',
  'Cherry',
  'Crown',
  'Detective Hat',
  'Golden Egg',
  'Nest',
  'Nimbus',
  'Patch',
  'Unicorn',
  'Horns',
  'Fire Sign',
  'None',
];

export const treeStageItems = [
  'Nest_low',
];

export const treeDecorations = [
  'Roof_low',
  'Garaland_low',
  'Lights_green_low',
  'Lights_low',
  'Lights_red_low',
  'Lights_blue_low',
  'Lights_yellow_low',
  'Tree_low',
  'Railing_Low',
  'Leav_1_low',
  'Leav_2_low',
  'Leav_3_low',
];

export const treeBodyElements = [
  'Tree_low',
];

export const mapTreeSkinItemIdToSkinId: Record<number, string> = {
  19: 'Birch'
};
