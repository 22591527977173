import { useMutation } from './useMutation';

type Input = {
  storeItemId: number;
};

export const useGetInvoiceLink = ({ storeItemId }: Input) => {
  const { mutate, ...rest } = useMutation<string>({
    url: `getInvoiceLink/${storeItemId}`,
    method: 'get',
  });

  return { getInvoiceLink: mutate, ...rest };
};
