import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Round } from '../../utils/math';
import { Progressbar } from '../Progressbar';

interface SquadProps {
  modifier: string | null;
  amount: number;
}

const UpdateIcons: { [key: string]: string } = {
  happiness: 'https://notwise.b-cdn.net/icons/icon-happy.svg',
  hunger: 'https://notwise.b-cdn.net/icons/icon-food.svg',
  health: 'https://notwise.b-cdn.net/icons/icon-health.svg',
};

export const UpdateBarWidget = (props: SquadProps) => {
  const { modifier, amount } = props;
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const updateItemImg = modifier ? UpdateIcons[modifier] : '';

  useEffect(() => {
    gsap.set(widgetRef.current, { opacity: 1, y: 0 });
    const timer = setTimeout(() => {
      if (widgetRef.current) {
        gsap.to(widgetRef.current, {
          opacity: 0,
          y: -20,
          duration: 0.3,
        });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [amount, modifier]);

  return (
    <div
      ref={widgetRef}
      className="flex w-6/12 m-auto items-center justify-between bg-white/[.3] p-3 rounded-full opacity-0 gap-2"
    >
      {updateItemImg && <img src={updateItemImg} width={20} height={20} alt="icon" />}
      <Progressbar
        winking={false}
        barcolor="bg-white"
        blurred={false}
        ht="min-h-3"
        percent="w"
        spark={false}
        value={amount}
        maxvalue={1000}
        customStyle={{ width: `${Round(amount * 5, 1)}%` }}
      />
    </div>
  );
};
