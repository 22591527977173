let tg;
if (window.Telegram) {
  tg = window.Telegram.WebApp;
}

//const tg_init = process.env.REACT_APP_ENV_DEV === 'true' ? '12345' : tg.initData; // ;
const tg_init = process.env.REACT_APP_TEST == 'true' ? '151472920' : tg.initData;
const tg_account = tg.initDataUnsafe.user;
const tg_haptic = tg.HapticFeedback;

export { tg_init, tg_account, tg_haptic };
