export const RAD2ANG = (rad: number) => (rad * 180) / Math.PI;

export const ANG2RAD = (ang: number) => (ang * Math.PI) / 180;

export const GET_RANDOM_VAL = (range: number) => Math.ceil(Math.random() * 100000000) % range;

export const Round = (value: number, precision: number) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getValidKey = (object: any) => {
  return Object.keys(object).filter((key) => key != 'id' && object[key] != null && object[key] > 0);
};
