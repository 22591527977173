import { REACT_APP_BACKEND_URL } from '../config';
import axios, { AxiosRequestConfig } from 'axios';
import { useUserStore } from '../store';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

type Input = Partial<{
  params: AxiosRequestConfig['params'];
  data: AxiosRequestConfig['data'];
}>;

export type QueryCallback = {
  onSuccess?: (data?: AxiosRequestConfig['data']) => void;
  onError?: (error: unknown) => void;
};

type Params = {
  url: string;
  method: AxiosRequestConfig['method'];
} & QueryCallback;

export const useMutation = <T,>({ url, method, onSuccess, onError }: Params) => {
  const token = useUserStore((state) => state.user.token);

  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(
    async (input?: Input) => {
      setIsLoading(true);

      try {
        const res = await axios<T>({
          method,
          url: `${REACT_APP_BACKEND_URL}/${url}`,
          params: input?.params,
          data: input?.data,
          headers: { Authorization: `Bearer ${token}` },
        });
        onSuccess?.(res.data);
        return res.data;
      } catch (error) {
        setError(error);

        if (onError) {
          onError(error);
        } else {
          toast.error('Something went wrong!', {style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
            }});
        }
      } finally {
        setIsLoading(false);
      }
    },
    [method, onError, onSuccess, token, url],
  );

  return {
    mutate,
    error,
    isLoading,
  };
};
