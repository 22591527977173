import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';
import { CDN_GATEWAY_BASE } from '../../../config';
import { Vector3 } from '@react-three/fiber/dist/declarations/src/three-types';

export const MidPlane = ({
  position
}: {
  position: Vector3;
}) => {
  const texture = useLoader(THREE.TextureLoader, `${CDN_GATEWAY_BASE}/images/bg-mid.webp`);

  return (
    <Plane args={[30, 140]} position={position}>
      <meshLambertMaterial attach="material" map={texture} />
    </Plane>
  );
};
