import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import { useShopStore, useUserStore } from '../../store';
import { StoreGoodType } from '../../types/interface';
import IconLeft from '../../assets/icon-left.svg';
import useAnimation from '../../hooks/useAnimation';
import { MainButton } from '../../components';
import { SuccessfulPurchaseScreen } from './SuccessfulPurchaseScreen';
import { useBuyGoods, useGetInventory, useGetInvoiceLink } from '../../api';
import toast from 'react-hot-toast';
import { StoreItemType } from '../Store/StoreItemType';

const potentialLoot = [
  {
    img: 'https://staging.masonsplay.com/assets/images/egg1.webp',
    isRare: false,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg2.webp',
    isRare: false,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg3.webp',
    isRare: true,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg4.webp',
    isRare: true,
  },
];

const itemNoteMapping: Partial<Record<StoreGoodType, { prefix: string; suffix: string; icon: string }>> = {
  food: {
    prefix: 'Restore your',
    icon: 'https://notwise.b-cdn.net/icons/icon-food.svg',
    suffix: 'Hunger',
  },  medicine: {
    prefix: 'Restore your',
    icon: 'https://notwise.b-cdn.net/icons/icon-health.svg',
    suffix: 'Health',
  },
};

export const StoreItemDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { userInfo, setUserInfo } = useUserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));

  const storeItem = useShopStore((state) => state.storeGoods?.find((storeItem) => storeItem.id.toString() === id));

  const [count, setCount] = useState<number>(1);

  const [isSuccessScreenShown, setIsSuccessScreenShown] = useState(false);

  const { getInvoiceLink } = useGetInvoiceLink({ storeItemId: Number(id) });

  const { buyGoods } = useBuyGoods({
    onSuccess: () => toast('Item bought successfully', {icon: '👏', style: {
        borderRadius: '30px',
        background: '#090e1de3',
        color: '#fff',
      }}),
    onError: () => toast.error('Item purchase failed',{style: {
        borderRadius: '30px',
        background: '#090e1de3',
        color: '#fff',
      }}),
  });

  const { getInventory } = useGetInventory();

  const elementRef = useAnimation({
    from: { opacity: 0, y: 20 },
    to: { duration: 1, opacity: 1, y: 0, ease: 'power3.out', stagger: 0.2 },
  });

  if (!storeItem) {
    return null;
  }

  const isEggType = storeItem.type === 'egg';
  const starsPrice = storeItem?.stars_price !== null;

  const increase = () => {
    if (storeItem.price !== undefined) {
      if (isEggType) {
        setCount(count + 1);
      } else {
        if (userInfo.coins > storeItem.price * count) setCount(count + 1);
      }
    }
  };

  const decrease = () => {
    if (count > 1) setCount(count - 1);
  };

  const buyItem = async () => {
    if (isEggType) {
      const link = await getInvoiceLink({ params: { amount: count } });

      if (link) {
        WebApp.openInvoice(link, (status) => {
          if (status === 'paid') {
            setIsSuccessScreenShown(true);
          }

          if (status === 'failed') {
            toast.error('Payment failed!',{style: {
              borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
            }});
          }
        });
      }
    } else {
      await buyGoods({ data: { good_id: storeItem.id, good_count: count } });
    }
    const inventories = await getInventory();

    if (inventories) {
      setUserInfo({ ...userInfo, inventories });
    }
  };

  const noteMappingItem = itemNoteMapping[storeItem.type];

  WebApp.BackButton.onClick(() => {
    navigate('/store');
  });

  if (isSuccessScreenShown) {
    return <SuccessfulPurchaseScreen onCloseClick={() => setIsSuccessScreenShown(false)} purchasedGood={storeItem} />;
  }

  return (
    <div className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 bg-[#080D1B]">
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 bottom-0 rounded-[50%] z-0"></div>

      <div className="relative h-screen w-full bg-cover overflow-y-auto scrollable no-scrollbar top-0 left-0 px-5 pt-6">
        <div className="flex flex-col justify-between h-full">
          <div className="w-full" ref={elementRef}>

            <div className="relative flex flex-col justify-center items-center gap-6 px-2 mt-2">
              {!isEggType && (
                <div className="absolute left-0 top-3 right-[-154px] w-fit m-auto">
                  <StoreItemType type={storeItem.type} />
                </div>
              )}

              <img
                className={`w-full aspect-square h-full rounded-[24px] bg-center bg-cover bg-no-repeat ${isEggType ? 'max-w-[180px]' : 'max-w-[256px]'}`}
                src={storeItem.img}
                alt="item"
              />

              <h4 className="text-2xl font-bold text-white text-center">{storeItem.name}</h4>
              <p className="text-sm font-normal text-white text-center">{storeItem.description}</p>

              {noteMappingItem && (
                <div className="flex text-white align-middle mt-9">
                  <span className="mr-1">{noteMappingItem.prefix}</span>
                  <img src={noteMappingItem.icon} alt="img" className="w-6 h-auto" />
                  <span className="font-medium">{noteMappingItem.suffix}</span>
                </div>
              )}
            </div>
          </div>

          {isEggType && (
            <div className="w-full flex flex-col items-center space-y-4 z-10 mt-8">
              <div className="text-white font-bold">Potential loot</div>
              <div className="flex space-x-2">
                {potentialLoot.map(({ img, isRare }, idx) => (
                  <div className="relative" key={idx}>
                    {isRare && (
                      <div
                        className="absolute rounded-[15px] px-1.5 py-1 text-xs text-center text-white font-semibold top-[-8px] right-[-5px]"
                        style={{
                          background: 'rgba(217, 217, 217, 0.56)',
                          backdropFilter: 'blur(11px)',
                        }}
                      >
                        Rare
                      </div>
                    )}
                    <img className="rounded-2xl w-[68px] h-auto" src={img} alt="item" />
                  </div>
                ))}
              </div>
              <div className="text-sm opacity-80 text-white">1-3 Items drop guaranteed</div>
            </div>
          )}

          <div className="z-10">
            <div className={`w-full flex justify-between items-center ${isEggType ? 'mb-5' : 'mb-6'}`}>
              <div className="flex justify-center items-center gap-2">
                <img
                  className="w-5 h-5"
                  alt="img"
                  src={
                    starsPrice
                      ? 'https://notwise.b-cdn.net/icons/stars.svg'
                      : 'https://notwise.b-cdn.net/icons/coin-icon.png'
                  }
                />
                <p className="text-2xl font-bold text-white">{storeItem?.price ? storeItem?.price : storeItem?.stars_price}</p>
              </div>

              <div
                className="flex justify-center items-center rounded-lg h-8"
                style={{
                  background: 'rgba(245, 246, 245, 0.22)',
                }}
              >
                <button
                  className="flex justify-center items-center w-8 h-full text-xl font-normal text-white pointer-events-auto"
                  onClick={decrease}
                >
                  -
                </button>
                <p className="h-full w-8 text-sm font-bold text-white text-center px-2 align-center leading-8">
                  {count}
                </p>
                <button
                  className="flex justify-center items-center w-8 h-full text-xl font-normal text-white pointer-events-auto"
                  onClick={increase}
                >
                  +
                </button>
              </div>
            </div>

            {!starsPrice && (
              <div className="flex justify-center items-center gap-2 mb-4 w-full">
                <span className="text-sm font-medium text-white">Balance after purchase:</span>
                <img alt="img" src="https://notwise.b-cdn.net/icons/coin-icon.png" width={18} height={18} />
                <p className="text-sm font-bold text-white">
                  {storeItem?.price === undefined ? 'N/A' : userInfo.coins - storeItem?.price * count}
                </p>
              </div>
            )}

            <div className="mb-6">
              { userInfo.coins - storeItem?.price * count >= 0 ?
              <MainButton
                isLoading={false}
                btnText={`Buy ${count} for ${(storeItem?.price ? storeItem?.price : storeItem?.stars_price ?? 0) * count} ${starsPrice ? 'stars' : 'coins'}`}
                onClick={buyItem}
              />
                :
                <button
                  disabled
                  type="button"
                  className="w-full text-white  bg-gradient-to-tl from-orange-800 bg-amber-600 opacity-50 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center"
                >
                  Insufficient balance
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
