import { MODEL_URLS } from "../../constants";

export const getViewByGameMode = (gameMode: string): SceneView => {
  return [ 'training', 'fighting', 'battle' ].includes(gameMode) ? 'TempleView' : 'TreeView';
};

export const Views: Record<string, View> = {
  TreeView: {
    preloadAssets: [
      MODEL_URLS.egg,
      MODEL_URLS.owl,
      MODEL_URLS.tree,
      MODEL_URLS.dummy,
      MODEL_URLS.notwise1_effect,
      MODEL_URLS.notwise2_effect,
    ],
    cameraPosition: 7.0,
  },
  TempleView: {
    preloadAssets: [
      MODEL_URLS.dummy,
      MODEL_URLS.owl,
      MODEL_URLS.notwise1_effect,
      MODEL_URLS.notwise2_effect,
    ],
    cameraPosition: -205.0,
  },
};

export type SceneView = keyof typeof Views;

type View = {
  preloadAssets: string[];
  cameraPosition: number;
}
