import { StoreItem } from '../../components';
import { StoreGood } from '../../types/interface';

type Props = {
  goods: Array<StoreGood>;
};

export const Tab = ({ goods }: Props) => {
  return (
    <div className="grid grid-cols-4 gap-y-4 gap-x-2  items-center px-4">
      {goods.map((good, idx) => (
        <StoreItem key={idx} good={good} />
      ))}
    </div>
  );
};
