import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import WebApp from '@twa-dev/sdk';
import { useNavigateByExp } from '../../hooks/useNavigateByExp';
import { useGameStore, useUserStore } from '../../store';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const FrenInfo = () => {
  const { id } = useParams(); // Extracts the id from the URL
  const navigateByExp = useNavigateByExp();
  const totalExp = useUserStore((state) => state.user.totalExp);
  const { foreignOwl, setForeignOwl } = useGameStore();

  useEffect(() => {
    const fetchFrenInfo = async () => {
      try {
        const response = await axios.get(`https://api.masonsplay.com/fren/info/${id}/`);

        setForeignOwl(response.data);
      } catch (error) {
        console.error('Error fetching Fren Info:', error);
      }
    };

    fetchFrenInfo();

    return () => {
      setForeignOwl(null);
    };
  }, [id]);

  WebApp.BackButton.onClick(() => {
    WebApp.BackButton.hide();

    setForeignOwl(null);
    navigateByExp(totalExp);
  });

  if (!foreignOwl) {
    return null;
  }

  return (
    <Wrapper className="absolute bg-gradient-to-t from-[#00000036] via-[transparent] to-[#140D2E] z-2">
      <div className="w-80 flex justify-between">
        <div className="text-white/[60%]">
          {foreignOwl.username ? foreignOwl.username : <b>Fren ID {foreignOwl.tg_id}</b>}
        </div>
        <div
          className="text-white/[60%]"
          onClick={() => {
            setForeignOwl(null);
            navigateByExp(totalExp);
          }}
        >
          Back X
        </div>
      </div>
      <div className="w-80 bg-white/[.1] rounded-2xl flex justify-between px-4 py-3 backdrop-blur">
        <div className="w-3/12 text-white flex flex-col justify-center items-center">
          <img className="w-4 h-4 rounded-full" src={`https://notwise.b-cdn.net/icons/strength.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">strength</span>
          <b>{foreignOwl.strength}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center">
          <img className="w-4 h-4 rounded-full" src={`https://notwise.b-cdn.net/icons/agility.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">agility</span>
          <b>{foreignOwl.agility}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center">
          <img className="w-4 h-4 rounded-full" src={`https://notwise.b-cdn.net/icons/wisdom.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">wisdom</span>
          <b>{foreignOwl.wisdom}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center">
          <img className="w-4 h-4 rounded-full" src={`https://notwise.b-cdn.net/icons/endurance.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">endurance</span>
          <b>{foreignOwl.endurance}</b>
        </div>
      </div>
    </Wrapper>
  );
};
