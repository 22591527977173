import { BattleState } from '../store';
import { useQuery } from './useQuery';

type User = {
  achievements: Array<unknown>;
  agility: number;
  avatar: string | null;
  coins: number;
  earned_from_referrals: number;
  endurance: number;
  energy: number;
  experience: number;
  eyes_color: string;
  frens_count: number;
  happiness: number;
  health: number;
  hunger: number;
  invited_by: null;
  is_premium: false;
  lang: string;
  last_energy_used: string;
  last_feeding: string | null;
  last_happiness: string | null;
  last_holding: string | null;
  level: number;
  rank: number;
  skill_points: number;
  squad: {
    chatId: string;
    coins: string;
    createdAt: string;
    id: number;
    leagueId: number;
    logo: string;
    members: number;
    name: string;
    slug: string;
    telegramId: null;
  };
  strength: number;
  temperature: number;
  tg_id: string;
  username: string;
  wisdom: number;
  current_fight: BattleState | null;
  has_frens: boolean;
};

export const useGetUser = () => {
  const { data, ...rest } = useQuery<User>({
    url: 'player/',
    method: 'get',
  });

  return { userInfo: data, ...rest };
};
