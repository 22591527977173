import { useGetUser, useTokenInitializer } from '../api';
import { useNavigateByExp } from './useNavigateByExp';
import { useGameStore, useUserStore } from '../store';
import { useEffect } from 'react';
import { tg_account, tg_init } from '../utils/telegramapi';
import { useGetInventoryQuery } from '../api/useGetInventoryQuery';
import { useNavigate } from 'react-router';

export const useUserInitializer = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();

  const setUserInfo = useUserStore((state) => state.setUser);
  const user = useUserStore((state) => state.user);

  const { userInfo, refetch: refetchUser } = useGetUser();
  const { inventory } = useGetInventoryQuery();
  const { setBattleState, setGameMode } = useGameStore();

  useTokenInitializer();

  useEffect(() => {
    if (!userInfo || !inventory) {
      return;
    }

    const updatedUserInfo = {
      achievements: userInfo.achievements,
      totalExp: userInfo.experience,
      avatar: userInfo.avatar,
      temperature: userInfo.temperature,
      energy: userInfo.energy,
      level: userInfo.level,
      skillPoints: userInfo.skill_points,
      coins: userInfo.coins,
      strength: userInfo.strength,
      agility: userInfo.agility,
      wisdom: userInfo.wisdom,
      endurance: userInfo.endurance,
      hunger: userInfo.hunger,
      tg_id: process.env.REACT_APP_TEST === 'true' ? tg_init : tg_account?.id,
      username: userInfo.username ? userInfo.username : null,
      isPremium: userInfo.is_premium,
      lang: userInfo.lang,
      inventories: inventory.map((item) => ({
        good: item.good,
        amount: item.amount,
        isEquipped: item.is_equipped,
      })),
      rank: userInfo.rank,
      health: userInfo.health,
      happiness: userInfo.happiness,
      fetchedLastHeating: userInfo.last_holding,
      lastFeeding: userInfo.last_feeding,
      fetchedLastEnergyUsed: userInfo.last_energy_used,
      squad: userInfo.squad,
      last_energy_used: null,
      last_heating: null,
      current_fight: userInfo.current_fight ?? null,
      hasFrens: userInfo.has_frens,
    };

    setUserInfo(updatedUserInfo);

    if (updatedUserInfo.current_fight) {
      setBattleState(updatedUserInfo.current_fight);

      setGameMode('battle');
      navigate('/fight');
    } else {
      navigateByExp(userInfo.experience);
    }
  }, [inventory, navigateByExp, setUserInfo, userInfo]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!user.tg_id) {
        return;
      }

      if (document.visibilityState === 'visible') {
        refetchUser();
      }
    };

    window.addEventListener('focus', () => {
      handleVisibilityChange();
    });

    return () => {
      document.removeEventListener('focus', handleVisibilityChange);
    };
  }, [refetchUser, user.tg_id]);
};
