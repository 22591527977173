import { useQuery } from './useQuery';

interface Fren {
  tg_id: number;
  username: string;
  coins: number;
  frens_count: number | null;
  avatar: string | null;
}
interface FrenList {
  referer: Fren;
  frens: Fren[];
}

export const useGetFrens = () => {
  const { data, ...rest } = useQuery<FrenList>({
    url: 'frens/',
    method: 'get',
  });

  return { data, ...rest };
};
