import { useState } from 'react';
import WebApp from '@twa-dev/sdk';
import { useShopStore, useUserStore } from '../../store';
import { useNavigateByExp } from '../../hooks';
import { StoreGoodType } from '../../types/interface';
import { MainNav } from '../../components';
import { Tabs } from './Tabs';
import { Tab } from './Tab';
import Starfield from "../../components/Starfield";

export const Store = () => {
  const storeGoods = useShopStore((state) => state.storeGoods);

  const visibleStoreTypes = new Set(storeGoods?.map((item) => item.type));

  const [activeTab, setActiveTab] = useState<StoreGoodType | 'all'>('food');

  const currentTabGoods =
    activeTab === 'all' ? storeGoods ?? [] : storeGoods?.filter((item) => item.type === activeTab);

  const navigateByExp = useNavigateByExp();
  const userInfo = useUserStore((state) => state.user);

  if (!userInfo) {
    return null;
  }

  WebApp.BackButton.onClick(() => {
    WebApp.BackButton.hide();
    navigateByExp(userInfo.totalExp);
  });

  if (!visibleStoreTypes) {
    return null;
  }

  return (
    <div className="block w-full h-full overflow-x-hidden absolute top-0 left-0 bg-[#080D1B]">
      <Starfield amount={30}/>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 bottom-0 rounded-[50%] z-0"></div>

      <div className="h-screen overflow-y-auto scrollable no-scrollbar">
        <div className="w-full pb-32 mt-8 z-10">
          <div className="flex flex-col items-center justify-center w-full mb-1">
            <h6 className="text-2xl font-bold text-white">
              <span className="text-[#ffd323] empires">Wise</span> Store
            </h6>
            <span className="mt-1 font-medium text-white/[70%] tex-md">Goods for pets and more</span>
          </div>

          <div className="my-8 px-5">
            <Tabs storeTypes={['all', ...Array.from(visibleStoreTypes)]} selected={activeTab} onChange={setActiveTab} />
          </div>
          {currentTabGoods && <Tab goods={currentTabGoods} />}
        </div>
      </div>

      <MainNav
        userExp={userInfo.totalExp}
        skillPoints={userInfo.skillPoints}
        isBranch={false}
        gameMode="normal"
        setIsNeedShown={false}
        setGameMode={null}
        isShop
      />
    </div>
  );
};
