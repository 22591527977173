import { create } from 'zustand';
import { UserInfo } from '../types/interface';

export const useUserStore = create<UserInfo>((set, get) => ({
  user: {
    avatar: null,
    achievements: [],
    totalExp: 0,
    temperature: null,
    energy: null,
    skillPoints: 0,
    level: 0,
    coins: 0,
    strength: 0,
    agility: 0,
    wisdom: 0,
    endurance: 0,
    hunger: 0,
    tg_id: 0,
    username: null,
    isPremium: false,
    lang: null,
    inventories: null,
    token: '',
    rank: 0,
    health: 0,
    happiness: 0,
    lastFeeding: null,
    fetchedLastHeating: null,
    fetchedLastEnergyUsed: null,
    squad: null,
    last_heating: null,
    last_energy_used: null,
    current_fight: null,
  },
  foreigns: null,
  setUser: (payload) =>
    set((state) => ({
      ...state,
      user: {
        ...payload,
        token: state.user.token,
      },
    })),
  setToken: (token: string) => set((state) => ({ ...state, user: { ...state.user, token } })),
  setAchievements: (achievements: Array<unknown>) => set((state) => ({ ...state, user: { ...state.user, achievements } })),
  addExperienceAndReduceEnergy: (experienceToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      const updatedExperience = state.user.totalExp + experienceToAdd;
      const updatedEnergy = state.user.energy - energyToReduce;

      return {
        ...state,
        user: {
          ...state.user,
          totalExp: updatedExperience,
          energy: updatedEnergy,
        },
      };
    }),
  addHappinessAndReduceEnergy: (happinessToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          happiness: state.user.happiness + happinessToAdd,
          energy: state.user.energy - energyToReduce,
        },
      };
    }),
  addTemperatureAndReduceEnergy: (temperatureToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.temperature === null || state.user.energy === null) {
        return state;
      }

      const newEnergy = state.user.energy - energyToReduce;

      return {
        ...state,
        user: {
          ...state.user,
          temperature: state.user.temperature + temperatureToAdd,
          energy: newEnergy > 0 ? newEnergy : 0,
        },
      };
    }),
  setLevel: (level: number) => set(state => ({ ...state, level })),
  decreaseTemperature: (temperature: number) =>
    set((state) => {
      if (state.user.temperature === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          temperature: Math.max(0, state.user.temperature - temperature),
        },
      };
    }),
  addEnergy: (energy: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          energy: Math.min(500, state.user.energy + energy),
        },
      };
    }),
  updateByUserInteraction: (last_energy_used: number, last_heating: number, temperature: number, action: string) =>
    set((state) => {
      if (action === 'heat') {
        return {
          user: {
            ...state.user,
            last_energy_used,
            last_heating: last_heating === 0 ? state.user.last_heating : last_heating,
            temperature: temperature === 0 ? state.user.temperature : temperature,
          },
        };
      }
      if (action === 'swipeOwl') {
        return {
          user: {
            ...state.user,
            happiness: state.user.happiness + 1,
          },
        };
      }
      if (action === 'feedOwl') {
        return {
          user: {
            ...state.user,
            hunger: state.user.hunger + 1,
          },
        };
      }

      return {
        user: {
          ...state.user,
          last_energy_used,
          last_heating: last_heating === 0 ? state.user.last_heating : last_heating,
          temperature: temperature === 0 ? state.user.temperature : temperature,
        },
      };
    }),
}));
