import { create } from 'zustand';
import { Coords } from '../hooks';
import { MarkerPosition } from '../components/GameSceneNew/hooks/owlControls/useBattleOwlControls';

type BattleUser = {
  tgid: string;
  hp: number;
  damage: number;
  attack: MarkerPosition | null;
  defence: MarkerPosition | null;
  critical: boolean;
  evaded: boolean;
}

export type BattleState = {
  error?: string;
  uuid: string;
  round: number;
  deadline: number;
  user1: BattleUser;
  user2: BattleUser;
}

interface GameRef {
  gameRef: any;
  isTouch: boolean;
  load: number;
  gameStage: number;
  gameMode: string;
  animating: boolean;
  foreignOwl: any;
  isNeedsVisible: boolean;
  needsPosition: Coords | null;
  currentPointerCoords: Coords | null;
  battleState: BattleState | null;
  incomingBattleState: BattleState | null;
  isBlocked: boolean;
  setNeedsPosition: (cords: Coords | null) => void;
  setNeedsVisibility: (isNeedsVisible: boolean) => void;
  setGameRef: (gameRef: any) => void;
  setIsTouch: (isTouch: boolean) => void;
  setLoad: (load: number) => void;
  setIsBlocked: (isBlocked: boolean) => void;
  setForeignOwl: (foreignOwl: any) => void;
  setGameStage: (gameStage: number) => void;
  setGameMode: (gameMode: string) => void;
  setAnimating: (animating: boolean) => void;
  setCurrentPointerCoords: (coords: Coords | null) => void;
  setBattleState: (battleState: BattleState | null) => void;
  setIncomingBattleState: (battleState: BattleState | null) => void;
}

export const useGameStore = create<GameRef>((set, get) => ({
  gameRef: null,
  isTouch: false,
  load: 0,
  gameStage: 1,
  gameMode: '',
  foreignOwl: null,
  animating: true,
  isNeedsVisible: true,
  needsPosition: null,
  currentPointerCoords: null,
  battleState: null,
  incomingBattleState: null,
  isBlocked: false,
  setNeedsPosition: (needsPosition) => set({ needsPosition }),
  setNeedsVisibility: (isNeedsVisible) => set({ isNeedsVisible }),
  setGameRef: (gameRef) => set({ gameRef }),
  setIsTouch: (isTouch) => set({ isTouch }),
  setLoad: (load) => set({ load }),
  setIsBlocked: (isBlocked) => set({ isBlocked }),
  setForeignOwl: (foreignOwl) => set({ foreignOwl }),
  setGameStage: (gameStage) => set({ gameStage }),
  setGameMode: (gameMode) => set({ gameMode }),
  setAnimating: (animating) => set({ animating }),
  setCurrentPointerCoords: (coords: Coords | null) => set({ currentPointerCoords: coords }),
  setBattleState: (battleState) => set({ battleState }),
  setIncomingBattleState: (incomingBattleState) => set({ incomingBattleState }),
}));
