import { REACT_APP_BACKEND_URL } from '../config';
import axios, { AxiosRequestConfig } from 'axios';
import { useUserStore } from '../store';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

type Params = {
  url: string;
  method: AxiosRequestConfig['method'];
  params?: AxiosRequestConfig['params'];
  data?: AxiosRequestConfig['data'];
  onSuccess?: (data?: AxiosRequestConfig['data']) => void;
  onError?: (error: unknown) => void;
};

export const useQuery = <T,>({ url, method, data, params, onSuccess, onError }: Params) => {
  const token = useUserStore((state) => state.user.token);

  const [resData, setResData] = useState<T | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(true);

  const sendRequest = useCallback(async () => {
    try {
      const res = await axios<T>({
        method,
        url: `${REACT_APP_BACKEND_URL}/${url}`,
        params,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });

      setResData(res.data);
      onSuccess?.(res.data);

      return res.data;
    } catch (error) {
      setError(error);
      if (onError) {
        onError(error);
      } else {
        toast.error('Something went wrong!', {style: {
          borderRadius: '30px',
            background: '#090e1de3',
            color: '#fff',
        }});
      }
    } finally {
      setIsLoading(false);
    }
  }, [data, method, onError, onSuccess, params, token, url]);

  useEffect(() => {
    if (!token) {
      return;
    }

    sendRequest();
  }, [sendRequest, token]);

  return {
    data: resData,
    refetch: sendRequest,
    error,
    isLoading,
  };
};
