import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import { CDN_GATEWAY_BASE } from '../../../config';
import { Vector3 } from '@react-three/fiber/dist/declarations/src/three-types';

export const BackgroundPlane = ({
  position
}: {
  position: Vector3;
}) => {
  const texture = useLoader(THREE.TextureLoader, `${CDN_GATEWAY_BASE}/images/bg-top.webp`);

  return (
    <Plane args={[31, 52]} position={position}>
      <meshLambertMaterial attach="material" map={texture} />
    </Plane>
  );
};
