import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import {
  chestData,
  owlBodyElements,
  owlEyesElements,
  owlHeadElements,
  owlNonChangableElements,
  owlBeakElements,
  owlLegsElements
} from '../constants';
import { CDN_GATEWAY_BASE } from '../config';

const textureLoader = new THREE.TextureLoader();

const environmentMap = textureLoader.load(`${CDN_GATEWAY_BASE}/images/map.png`);
environmentMap.mapping = THREE.EquirectangularReflectionMapping;

const onTextureLoaded = (texture: THREE.Texture) => {
  texture.colorSpace = "srgb";

  texture.flipY = false;
  texture.needsUpdate = true;
};

export const useOwlSkins = (
  scene: THREE.Object3D,
  equippedSkins: any
) => {
  const skinsLoopRef = useRef<any>(null);
  const skinsQueueRef = useRef<any[]>([]);
  const latestSkinRef = useRef<any>(null);

  const hideSkins = (scene: THREE.Object3D) => {
    scene.traverse((object: any) => {
      object.visible = false;
    });
  };

  const applyMaterial = (object: any) => {
    if (!object.material) {
      return;
    }

    object.material = object.material.clone();
    object.material.envMap = environmentMap;
  };

  const applyBaseSkin = async (targetSkinSlot: string, object: any) => {
    const baseMaterialPath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_BaseColor_Brown.png`;
    const normalMaterialPath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Normal_Brown.png`;

    object.material = new THREE.MeshLambertMaterial({
      map: textureLoader.load(baseMaterialPath, onTextureLoaded),
      normalMap: textureLoader.load(normalMaterialPath, onTextureLoaded),
    });
  };

  const applyEyesSkin = async (object: any, skinIdByTrait: Record<string, string>) => {
    const skinId = skinIdByTrait.eyes;

    if (!skinId) {
      return;
    }

    if (object.material) {
      object.material.dispose();
    }

    object.material = new THREE.MeshStandardMaterial({
      normalMap: object.material?.normalMap,
    });

    object.material.envMap = environmentMap;

    const mapTexturePath = `${CDN_GATEWAY_BASE}/textures/Eyes/Owl_low_T_Eyes_BaseColor_${skinId.replace(/\s/gi, '')}.png`;
    const roughnessTexturePath = `${CDN_GATEWAY_BASE}/textures/Eyes/Owl_low_T_Eyes_Roughness.png`;
    const metalnessTexturePath = `${CDN_GATEWAY_BASE}/textures/Eyes/Owl_low_T_Eyes_Metallic.png`;

    let hasCustomEyesSkin = false;

    try {
      hasCustomEyesSkin = (await fetch(mapTexturePath)).headers.get('content-type') === 'image/png';
    } catch {}

    if (hasCustomEyesSkin) {
      object.material.map = textureLoader.load(mapTexturePath, onTextureLoaded);
    } else {
      const defaultMapTexturePath = `${CDN_GATEWAY_BASE}/textures/Eyes/Owl_low_T_Eyes_BaseColor_Brown.png`;

      object.material.map = textureLoader.load(defaultMapTexturePath, onTextureLoaded);
    }

    object.material.roughnessMap = textureLoader.load(roughnessTexturePath, onTextureLoaded);
    object.material.metalnessMap = textureLoader.load(metalnessTexturePath, onTextureLoaded);

    object.material.roughness = 1;
    object.material.metalness = 0;
  };

  const applySkin = async (targetSkinSlot: string, object: any, skinIdByTrait: Record<string, string>) => {
    let skinId;

    if (targetSkinSlot === 'Body') {
      skinId = skinIdByTrait.body;
    }if (targetSkinSlot === 'Legs') {
      skinId = skinIdByTrait.body;
    } else if (targetSkinSlot === 'Head') {
      skinId = skinIdByTrait.head ?? skinIdByTrait.body;
    } else if (targetSkinSlot === 'Beak') {
      skinId = skinIdByTrait.body;
    } else if (targetSkinSlot === 'Eyes') {
      skinId = skinIdByTrait.eyes;
    }

    if (!skinId) {
      return;
    }

    if (object.material) {
      object.material.dispose();
    }

    object.material = new THREE.MeshStandardMaterial({
      normalMap: object.material?.normalMap,
    });

    object.material.envMap = environmentMap;

    let hasSkinFile = {
      map: false,
      normal: false,
      pbr: false,
    };

    const mapTexturePath = `${CDN_GATEWAY_BASE}/textures/${skinId}/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_BaseColor_${skinId}.png`;
    const normalMapTexturePath = `${CDN_GATEWAY_BASE}/textures/${skinId}/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Normal_${skinId}.png`;
    const roughnessTexturePath = `${CDN_GATEWAY_BASE}/textures/${skinId}/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Roughness_${skinId}.png`;
    const metalnessTexturePath = `${CDN_GATEWAY_BASE}/textures/${skinId}/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Metallic_${skinId}.png`;

    try {
      hasSkinFile.map = (await fetch(mapTexturePath)).headers.get('content-type') === 'image/png';
    } catch {}

    try {
      hasSkinFile.normal = (await fetch(normalMapTexturePath)).headers.get('content-type') === 'image/png';
    } catch {}

    try {
      hasSkinFile.pbr = (await fetch(roughnessTexturePath)).headers.get('content-type') === 'image/png';
    } catch {}

    if (hasSkinFile.map) {
      object.material.map = textureLoader.load(mapTexturePath, onTextureLoaded);
    } else {
      const defaultMapTexturePath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_BaseColor_Brown.png`;
      object.material.map = textureLoader.load(defaultMapTexturePath, onTextureLoaded);
    }

    if (hasSkinFile.normal) {
      object.material.normalMap = textureLoader.load(normalMapTexturePath, onTextureLoaded);
    } else {
      const defaultNormalMapTexturePath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Normal_Brown.png`;
      object.material.normalMap = textureLoader.load(defaultNormalMapTexturePath, onTextureLoaded);
    }

    if (hasSkinFile.pbr) {
      object.material.roughnessMap = textureLoader.load(roughnessTexturePath, onTextureLoaded);
      object.material.metalnessMap = textureLoader.load(metalnessTexturePath, onTextureLoaded);

      object.material.roughness = 1.0;
      object.material.metalness = 1.0;
    } else {
      const defaultRoughnessTexturePath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Roughness_Brown.png`;
      const defaultMetalnessTexturePath = `${CDN_GATEWAY_BASE}/textures/Brown/${targetSkinSlot}/Owl_low_T_${targetSkinSlot}_Metallic_Brown.png`;

      object.material.roughnessMap = textureLoader.load(defaultRoughnessTexturePath, onTextureLoaded);
      object.material.metalnessMap = textureLoader.load(defaultMetalnessTexturePath, onTextureLoaded);
    }
  };

  const applyWearable = async (object: any, wearableNodes: string[]) => {
    if (!wearableNodes) {
      return;
    }


    wearableNodes.forEach(objectId => {
      const targetObject = object.getObjectByName(objectId);

      if (!targetObject) {
        return;
      }

      targetObject.visible = true;
    });
  };

  const handleSkinsLoop = async () => {
    const equippedSkins = skinsQueueRef.current.shift();

    scene.visible = false;

    const hasNoSkins = equippedSkins?.length === 0;
    const skinIdByTrait: Record<string, string> = {};

    hideSkins(scene);

    if (!hasNoSkins) {
      equippedSkins?.forEach((equippedItem: any) => {
        const { traits } = equippedItem.good;

        if (!traits) {
          return;
        }

        Object.entries(traits).forEach(([trait, value]) => {
          skinIdByTrait[trait] = value as string;
        });
      });
    }

    const skinsPromises: Promise<void>[] = [];

    scene.traverse((object: any) => {
      if (object === scene) {
        return;
      }

      const isBodyPart = owlBodyElements.includes(object.name);
      const isHeadPart = owlHeadElements.includes(object.name);
      const isEyesPart = owlEyesElements.includes(object.name);
      const isLegsPart = owlLegsElements.includes(object.name);
      const isBeakPart = owlBeakElements.includes(object.name);
      const isNonChangable = owlNonChangableElements.includes(object.name);

      const isOwlPart = isBodyPart || isHeadPart || isEyesPart || isLegsPart || isBeakPart || isNonChangable;

      applyMaterial(object);

      const targetSkinSlot = [
        isBodyPart && 'Body',
        isHeadPart && 'Head',
        isLegsPart && 'Legs',
        isBeakPart && 'Beak',
        isEyesPart && 'Eyes'
      ].filter(Boolean)[0];

      if (targetSkinSlot) {
        if (hasNoSkins) {
          if (isEyesPart) {
            skinsPromises.push(applyEyesSkin(object, skinIdByTrait));
          } else if (!isNonChangable) {
            skinsPromises.push(applyBaseSkin(targetSkinSlot!, object));
          }
        } else {
          if (isEyesPart) {
            skinsPromises.push(applyEyesSkin(object, skinIdByTrait));
          } else {
            skinsPromises.push(applySkin(targetSkinSlot!, object, skinIdByTrait));
          }
        }
      }

      if (isOwlPart) {
        let { parent } = object;

        while (parent) {
          if (!parent.visible && parent !== scene) {
            parent.visible = true;
          }
          parent = parent.parent;
        }

        object.visible = true;
      }
    });

    if (skinIdByTrait.chest) {
      applyWearable(scene, chestData[skinIdByTrait.chest]);
    }

    await Promise.all(skinsPromises);

    if (skinsQueueRef.current.length > 0) {
      handleSkinsLoop();
    } else {
      skinsLoopRef.current = false;
      scene.visible = true;
    }
  };

  useEffect(() => {
    const compare = JSON.stringify(latestSkinRef.current) === JSON.stringify(equippedSkins);

    if (latestSkinRef.current && compare) {
      return;
    }

    latestSkinRef.current = equippedSkins;
    skinsQueueRef.current.push(equippedSkins);

    if (!skinsLoopRef.current) {
      skinsLoopRef.current = true;
      handleSkinsLoop();
    }
  }, [equippedSkins]);

  useEffect(() => {
    if (!latestSkinRef.current) {
      return;
    }

    skinsQueueRef.current.push(latestSkinRef.current);

    if (!skinsLoopRef.current) {
      skinsLoopRef.current = true;
      handleSkinsLoop();
    }
  }, [scene]);
};
