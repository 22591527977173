import { create } from 'zustand';
import { Wallet } from '../types/interface';

export const useWalletStore = create<Wallet>((set) => ({
  wallet: {
    address: '',
    connected: false,
    hasNFT: false,
  },
  setWallet: (payload) => set((state) => ({ ...state, wallet: payload })),
}));
