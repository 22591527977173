export const RedTriangle = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.641 0.885428C15.7643 -0.0622318 17.4922 0.56147 17.7513 2.00809L20.8599 19.3673C21.119 20.8139 19.7149 21.9985 18.3325 21.4995L1.7447 15.5121C0.362365 15.0131 0.0385458 13.2049 1.16183 12.2572L14.641 0.885428Z"
        fill="#F85563"
      />
    </svg>
  );
};
