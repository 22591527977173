import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { mapTreeSkinItemIdToSkinId, treeBodyElements, treeDecorations, treeStageItems } from '../constants';
import { CDN_GATEWAY_BASE, LEVELS } from '../config';

const textureLoader = new THREE.TextureLoader();

const environmentMap = textureLoader.load(`${CDN_GATEWAY_BASE}/images/map.png`);
environmentMap.mapping = THREE.EquirectangularReflectionMapping;

const onTextureLoaded = (texture: THREE.Texture) => {
  texture.colorSpace = "srgb";

  texture.flipY = false;
  texture.needsUpdate = true;
};

export const useTreeSkins = (
  scene: THREE.Object3D,
  equippedSkins: any,
  currentExp: number
) => {
  const skinsLoopRef = useRef<any>(null);
  const skinsQueueRef = useRef<any[]>([]);
  const latestSkinRef = useRef<any>(null);
  const lastCurrentExpRef = useRef<number>(-1);

  const hideSkins = (scene: THREE.Object3D) => {
    scene.traverse((object: any) => {
      const isStageItem = treeStageItems.includes(object.name);
      const isDecoration = treeDecorations.includes(object.name);

      object.visible = !(isDecoration || isStageItem);
    });
  };

  const applyStageItems = (object: THREE.Object3D) => {
    if (!object) {
      return;
    }
    
    // NOTE Nest
    if (currentExp < LEVELS[1] && object.name === 'Nest_low') {
      object.visible = true;
    }
  };

  const applyDecorationSkinById = (object: THREE.Object3D, equippedItemIds: number[]) => {
    if (equippedItemIds.includes(20)) {
      // NOTE Empire-state building

      [
        'Pipes_Low',
        'Railing_Low',
        'Roof_low',
      ].includes(object.name) && (object.visible = true);

      [
        'Tree_low',
        'Leav_1_low',
        'Leav_2_low',
        'Leav_3_low',
      ].includes(object.name) && (object.visible = false);
    } else {
      // NOTE Tree

      [
        'Tree_low',
        'Leav_1_low',
        'Leav_2_low',
        'Leav_3_low',
      ].includes(object.name) && (object.visible = true);

      if (equippedItemIds.includes(14)) {
        [
          'Garaland_low',
          'Lights_low',
          'Lights_yellow_low',
        ].includes(object.name) && (object.visible = true);
      }
  
      if (equippedItemIds.includes(13)) {
        [
          'Garaland_low',
          'Lights_green_low',
          'Lights_low',
          'Lights_red_low',
          'Lights_blue_low',
          'Lights_yellow_low',
        ].includes(object.name) && (object.visible = true);
      }
    }
  };

  const applyTreeColorSkins = (object: THREE.Mesh, equippedItemIds: number[]) => {
    const skinIndex = equippedItemIds.find(itemId => {
      if (mapTreeSkinItemIdToSkinId[itemId]) {
        return true;
      }
    });
    const skinId = mapTreeSkinItemIdToSkinId[skinIndex!];

    if (!skinId) {
      return;
    }

    if (object.material) {
      const material = object.material as THREE.MeshStandardMaterial;
      
      material.map = textureLoader.load(`${CDN_GATEWAY_BASE}/textures/Tree/${skinId}/Tree_low_T_BaseColor_${skinId}.png`, onTextureLoaded);
    }
  };

  const handleSkinsLoop = async () => {
    const equippedSkins = skinsQueueRef.current.shift();

    scene.visible = false;

    const equippedItemIds = equippedSkins?.map((item: any) => item?.item?.id).filter(Boolean);

    hideSkins(scene);

    const skinsPromises: Promise<void>[] = [];

    scene.traverse((object: any) => {
      if (object === scene) {
        return;
      }

      const isStageItem = treeStageItems.includes(object.name);
      const isDecoration = treeDecorations.includes(object.name);
      const isSkinnable = treeBodyElements.includes(object.name);

      if (isStageItem) {
        applyStageItems(object);
      }

      if (isDecoration) {
        applyDecorationSkinById(object, equippedItemIds);
      }

      if (isSkinnable) {
        applyTreeColorSkins(object, equippedItemIds);
      }

      if (object.material) {
        object.material.envMap = environmentMap;
      }
    });

    await Promise.all(skinsPromises);

    if (skinsQueueRef.current.length > 0) {
      handleSkinsLoop();
    } else {
      skinsLoopRef.current = false;
      scene.visible = true;
    }
  };

  useEffect(() => {
    const compare = JSON.stringify(latestSkinRef.current) === JSON.stringify(equippedSkins)
      && currentExp === lastCurrentExpRef.current;

    if (latestSkinRef.current && compare) {
      return;
    }

    latestSkinRef.current = equippedSkins;
    skinsQueueRef.current.push(equippedSkins);
    
    if (!skinsLoopRef.current) {
      skinsLoopRef.current = true;
      handleSkinsLoop();
    }
  }, [equippedSkins, currentExp, scene]);

  useEffect(() => {
    if (!latestSkinRef.current) {
      return;
    }

    skinsQueueRef.current.push(latestSkinRef.current);
    
    if (!skinsLoopRef.current) {
      skinsLoopRef.current = true;
      handleSkinsLoop();
    }
  }, [scene]);
};
