import { useQuery } from './useQuery';

type EnemyInfo = {
  username: string;
  skin: Array<unknown>;
  max_hp: number;
  current_hp: number;
}

type Input = {
  id: string;
};

export const useGetEnemyInfo = ({ id }: Input) => {
  const { data, ...rest } = useQuery<EnemyInfo>({
    url: `getEnemyInfo/${id}/`,
    method: 'get',
  });

  return {
    userInfo: {
      "username": "test",
      "skin": [],
      "max_hp": 20,
      "current_hp": 20,
    },
    ...rest
  };

  // return { userInfo: data, ...rest };
};
