// AmbientSound.js
import { useEffect } from 'react';
import { Howl } from 'howler';

export const AmbientSound = ({ src, volume = 0 }) => {
  useEffect(() => {
    // Initialize the Howl object
    const sound = new Howl({
      src: [src],
      autoplay: true,
      volume: volume,
      loop: true, // Loop the ambient soun
    });

    // Play the sound
    sound.once('load', function () {
      sound.play();
    });

    // Cleanup function to stop the sound when the component unmounts
    return () => {
      sound.stop();
    };
  }, [src, volume]);

  return null; // This component does not render anything visible
};
