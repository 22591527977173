import { Navigate, Route, Routes } from 'react-router-dom';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { Toaster } from 'react-hot-toast';
import { GameSceneNew, TonConnectProvider } from './components';
import { useEnergy, useTemperature } from './hooks';
import {
  Earn,
  Fight,
  FrenInfo,
  Frens,
  Home,
  Stories,
  InventoryItemDetails,
  LeaderBird,
  LeaderBoard,
  Profile,
  Squad,
  SquadList,
  Stage1,
  Stage2,
  Stage3,
  Store,
  StoreItemDetails,
  Train,
  Tree,
} from './pages';
import {WebSocketProvider} from "./components/WebSocketContext";

const App = () => {
  useEnergy();
  useTemperature();

  return (
    <TonConnectUIProvider
      manifestUrl="https://node1.irys.xyz/6jjdY6x_PUMEouHDDquPBalqKNU__bpecDp-erCKy5k"
      uiPreferences={{ theme: THEME.DARK }}
    >
      <div className="App w-screen h-screen select-none">
        <TonConnectProvider>
          <WebSocketProvider>
          {/* <ScreenOrientation> */}
          <GameSceneNew />
          {/* <LoadingProvider> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/stage1" element={<Stage1 />} />
            <Route path="/stage2" element={<Stage2 />}>
              <Route path="train" element={<Train />} />
            </Route>
            <Route path="/stage3" element={<Stage3 />} />
            <Route path="/squad" element={<Squad />} />
            <Route path="/squad-list" element={<SquadList />} />
            <Route path="/squad/:id" element={<LeaderBoard />} />
            <Route path="/leaderbird" element={<LeaderBird />} />
            <Route path="/fight" element={<Fight />} />
            <Route path="/fight-tree" element={<Tree />} />
            <Route path="/store" element={<Store />} />
            <Route path="/store/:id" element={<StoreItemDetails />} />
            <Route path="/frens" element={<Frens />} />
            <Route path="/earn" element={<Earn />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/inventory/details/:id" element={<InventoryItemDetails />} />
            <Route path="/freninfo/:id" element={<FrenInfo />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {/* </LoadingProvider> */}
          {/* </ScreenOrientation> */}
          </WebSocketProvider>
        </TonConnectProvider>
        <Toaster />
      </div>
    </TonConnectUIProvider>
  );
};

export default App;
