import * as THREE from 'three';

const disposeMaterial = (material: THREE.Material) => {
  material.dispose();

  Object.values(material).forEach((value) => {
    if (typeof value?.dispose === 'function') {
      value.dispose();
    }
  });
};

export const disposeModel = (scene: THREE.Object3D) => {
  if (!scene) {
    return;
  }

  scene.removeFromParent();
  
  scene.traverse((child: THREE.Object3D) => {
    const mesh = child as THREE.Mesh;
    const skinnedMesh = child as THREE.SkinnedMesh;

    if (mesh.geometry) {
      mesh.geometry.dispose();
    }

    if (mesh.material) {

      if (Array.isArray(mesh.material)) {
        mesh.material.forEach(disposeMaterial);
      } else {
        disposeMaterial(mesh.material);
      }
    }

    if (skinnedMesh.skeleton) {
      skinnedMesh.skeleton.dispose();
    }
  });
};
